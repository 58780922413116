import React, {useEffect, useState} from 'react'
import ReactECharts from 'echarts-for-react'
import {fetchMonthlyVisitorCount, MonthlyVisitorCountResponse} from '../../service/apiService'
import {ClipLoader} from 'react-spinners'

interface MonthlyLineGraphProps {
  filters: {
    poi_category: string
    city: string
    place_name: string[]
    date: string[]
  }
}

const defaultMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
const defaultVisitors = Array(12).fill(0)

const MIN_LOADING_TIME = 1000 // 1 second

const MonthlyLineGraph: React.FC<MonthlyLineGraphProps> = ({filters}) => {
  const currentYear = new Date().getFullYear().toString()
  const [selectedYear, setSelectedYear] = useState<string>(currentYear)
  const [monthlyVisitorData, setMonthlyVisitorData] = useState<{month: string; visitors: number}[]>(
    []
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [parentWidth, setParentWidth] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      if (!filters.date.some((date) => date.includes(selectedYear))) {
        setMonthlyVisitorData([])
        setLoading(false)
        return
      }

      const requestData = {
        place_name: filters.place_name,
        year: selectedYear,
        poi_category: filters.poi_category,
        city: filters.city,
        date: filters.date,
      }

      try {
        const response: MonthlyVisitorCountResponse = await fetchMonthlyVisitorCount(requestData)
        setMonthlyVisitorData(response.MonthlyVisitorCount)
      } catch (error) {
        console.error('Error fetching monthly visitor count:', error)
      } finally {
        const elapsedTime = Date.now() - startTime
        const remainingTime = MIN_LOADING_TIME - elapsedTime
        setTimeout(() => setLoading(false), remainingTime > 0 ? remainingTime : 0)
      }
    }

    const startTime = Date.now()
    fetchData()
  }, [filters.place_name, selectedYear, filters.date])

  const option = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['Visitors'],
      // Customize legend appearance here
      textStyle: {
        color: '#333', // Legend text color
        fontSize: 14, // Legend text size
      },
      itemWidth: 20, // Width of legend item symbol
      itemHeight: 14, // Height of legend item symbol
      bottom: 8, // Position the legend at the bottom
    },
    xAxis: {
      type: 'category',
      data: defaultMonths,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Visitors',
        data: monthlyVisitorData.length
          ? monthlyVisitorData.map((item) => item.visitors)
          : defaultVisitors,
        type: 'line',
        smooth: true,
        lineStyle: {
          color: '#FF6347',
        },
        itemStyle: {
          color: '#FF6347',
        },
      },
    ],
  }

  // Function to update width state on resize
  const updateParentWidth = () => {
    const element = document.getElementById('mounthly-line-wrapper')
    if (element) {
      const width = element.clientWidth
      setParentWidth(width)
    }
  }

  // Effect to add resize listener and initial width setting
  useEffect(() => {
    updateParentWidth() // Set initial width
    window.addEventListener('resize', updateParentWidth) // Add resize listener
    return () => {
      window.removeEventListener('resize', updateParentWidth) // Cleanup on component unmount
    }
  }, [])

  return (
    <div id='mounthly-line-wrapper' style={{width: '100%', position: 'relative'}}>
      {loading ? (
        <div
          style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '350px'}}
        >
          <ClipLoader color='#1e90ff' loading={loading} size={35} />
        </div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              padding: '0 20px',
              marginTop: '5px',
            }}
          >
            <div>
              <label htmlFor='yearSelect' className='font-sans fs-5 font-sans' style={{marginRight: '10px', fontSize: '15px'}}>
                Select Year:{' '}
              </label>
              <select
                id='yearSelect'
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                style={{
                  borderColor: 'gray',
                  borderRadius: '5px',
                  padding: '5px',
                  width: '100px',
                }}
                className='font-sans fs-6'
              >
                <option value='2021' className='font-sans fs-6'>2021</option>
                <option value='2022' className='font-sans fs-6'>2022</option>
                <option value='2023' className='font-sans fs-6'>2023</option>
                <option value='2024' className='font-sans fs-6'>2024</option>
              </select>
            </div>
          </div>
          <ReactECharts option={option} style={{height: '350px', width: `${parentWidth}px`}} />
        </>
      )}
    </div>
  )
}

export default MonthlyLineGraph
