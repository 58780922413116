import React, {useEffect, useState} from 'react'
import ReactECharts from 'echarts-for-react'
import {fetchWeeklyVisitorCount, WeeklyVisitorCountResponse} from '../../service/apiService'
import {ClipLoader} from 'react-spinners'

interface WeeklyLineGraphProps {
  filters: {
    place_name: string[]
    poi_category: string
    city: string
    date: string[]
  }
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const defaultWeeks = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5']
const defaultVisitors = Array(defaultWeeks.length).fill(0)

const MIN_LOADING_TIME = 1000 // 1 second

const WeeklyLineGraph: React.FC<WeeklyLineGraphProps> = ({filters}) => {
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const currentMonth = new Date().toLocaleString('default', {month: 'long'})
    return months.includes(currentMonth) ? currentMonth : 'January'
  })

  const [weeklyData, setWeeklyData] = useState<{[key: string]: {week: string; visitors: number}[]}>(
    {}
  )
  const [parentWidth, setParentWidth] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const monthName = e.target.value
    setSelectedMonth(monthName)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      const monthIndex = months.indexOf(selectedMonth) + 1
      const requestData = {
        place_name: filters.place_name,
        poi_category: filters.poi_category,
        city: filters.city,
        date: filters.date,
        month: monthIndex,
      }

      try {
        const response: WeeklyVisitorCountResponse = await fetchWeeklyVisitorCount(requestData)
        if (response.WeeklyVisitorCount[selectedMonth]) {
          setWeeklyData({
            ...weeklyData,
            [selectedMonth]: response.WeeklyVisitorCount[selectedMonth].map((item) => ({
              week: item.week,
              visitors: item.Visitor,
            })),
          })
        } else {
          console.warn(`No data found for month: ${selectedMonth}`)
          setWeeklyData({
            ...weeklyData,
            [selectedMonth]: [],
          })
        }
      } catch (error) {
        console.error('Error fetching weekly visitor count:', error)
      } finally {
        const elapsedTime = Date.now() - startTime
        const remainingTime = MIN_LOADING_TIME - elapsedTime
        setTimeout(() => setLoading(false), remainingTime > 0 ? remainingTime : 0)
      }
    }

    const startTime = Date.now()
    fetchData()
  }, [filters.place_name, selectedMonth])

  const data = weeklyData[selectedMonth] || []

  const option = {
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: data.length ? data.map((item) => item.week) : defaultWeeks,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Visitors',
        data: data.length ? data.map((item) => item.visitors) : defaultVisitors,
        type: 'line',
        smooth: true,
        lineStyle: {
          color: '#FF6347',
        },
        itemStyle: {
          color: '#FF6347',
        },
      },
    ],
  }

  // Function to update width state on resize
  const updateParentWidth = () => {
    const element = document.getElementById('weekly-line-graph-wrapper')
    if (element) {
      const width = element.clientWidth
      setParentWidth(width)
    }
  }

  // Effect to add resize listener and initial width setting
  useEffect(() => {
    updateParentWidth() // Set initial width
    window.addEventListener('resize', updateParentWidth) // Add resize listener
    return () => {
      window.removeEventListener('resize', updateParentWidth) // Cleanup on component unmount
    }
  }, [])
  return (
    <div id='weekly-line-graph-wrapper'>
      <div style={{textAlign: 'right', marginRight: '20px', marginTop: '5px'}}>
        <label htmlFor='month-select' style={{marginRight: '10px', fontSize: '15px'}}>
          Select Month:
        </label>
        <select
          id='month-select'
          value={selectedMonth}
          onChange={handleMonthChange}
          style={{
            borderColor: 'gray',
            borderRadius: '5px',
            padding: '5px',
          }}
        >
          {months.map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </select>
      </div>
      <div>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '350px',
            }}
          >
            <ClipLoader color='#1e90ff' loading={loading} size={35} />
          </div>
        ) : (
          <ReactECharts option={option} style={{height: '350px', width: `${parentWidth}px`}} />
        )}
      </div>
    </div>
  )
}

export default WeeklyLineGraph
