import React, {useState, useEffect, useMemo} from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import {KTIcon} from '../../../../_metronic/helpers'
import {CSVLink} from 'react-csv'
import {VisitorOrigin, fetchPlaceStatistics, fetchVisitorOrigin} from '../../service/apiService'
import {convertNameString, isNumber} from '../../utils'
import {object} from 'yup'
import {ClipLoader} from 'react-spinners'
import {left} from '@popperjs/core'

interface User {
  id: string
  place_id: string
  name: string
  visitors: number
  avg_daily_visitor: number
  area: number
  android_ios: number
  visitorOriginData: any
}

interface PlaceStatisticsTableProps {
  poi_category: string
  city: string
  place_name: string[]
  date: string[]
  rowClickAndSetBounds: any
  data: any
}

const VisitorOriginTable: React.FC<any> = ({subRowHeaders, subRow}) => {
  return (
    <>
      {subRowHeaders && (
        <TableContainer
          component={Paper}
          style={{
            maxHeight: '30rem',
            maxWidth: '48rem',
            overflowY: 'auto',
            position: 'relative',
            paddingBottom: '0px',
          }}
        >
          <Table size='medium' aria-label='details'>
            <TableHead style={{backgroundColor: '#F0F8FF', position: 'sticky', top: 0}}>
              <TableRow>
                {subRowHeaders?.map((item) => (
                  <TableCell className='fw-bold fs-6 text-gray-700 font-sans'>
                    {convertNameString(item)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {subRow.length > 0 ? (
                subRow.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell, colIndex) => {
                      const isVisitorsColumn = subRowHeaders[colIndex] === 'visitors';
                      const formattedValue = isVisitorsColumn ? Number(cell).toLocaleString('en-IN') : cell;
                      return (
                        <TableCell
                          key={colIndex}
                          className='fw-semibold font-sans fs-8 text-gray-900'
                          style={isNumber(cell) ? {textAlign: 'center'} : {textAlign: 'center'}}
                        >
                          {formattedValue}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={subRowHeaders.length} className='fw-semibold font-sans fs-8 text-gray-900'>
                    Visitor data not found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

const Row: React.FC<{row: User; rowClickAndSetBounds: any; activeRow: string}> = ({
  row,
  rowClickAndSetBounds,
  activeRow,
}) => {
  const {place_id, name, visitors, avg_daily_visitor, area, android_ios} = row
  const [open, setOpen] = useState(false)
  const [subRow, setSubRow] = useState<any>([])
  const [subRowHeaders, setSubRowHeaders] = useState<string[]>([])
  const displayName = name.replace(/_/g, ' ')

  useEffect(() => {
    fetchSubRowData()
    setOpen(false)
  }, [row])

  const fetchSubRowData = async () => {
    if (place_id) {
      const subRows = row?.visitorOriginData //await fetchSubRow(row)
      if (subRows && Array.isArray(subRows)) {
        const processedSubRows = subRows?.map((item) =>
          typeof item === 'object' ? Object.values(item) : []
        )
        setSubRow(processedSubRows)
        console.log(subRow,"data of subrows")
        const headers =
          subRows?.[0] && typeof subRows?.[0] === 'object' ? Object.keys(subRows?.[0]) : []
        setSubRowHeaders(headers)
      }
    }
  }
  return (
    <>
      <TableRow
        sx={{'& > *': {borderBottom: 'unset'}}}
        style={{cursor: 'pointer'}}
        className='fs-5 fw-bold font-sans'
        onClick={() => {
          rowClickAndSetBounds && rowClickAndSetBounds(name)
          setOpen(!open)
        }}
      >
        <TableCell align='center' className='table-cell-border'>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {activeRow === name ? (
              <KTIcon iconName='down' className='fs-2' />
            ) : (
              <KTIcon iconName='right' className='fs-2' />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          className='fw-semibold fs-6 table-cell-border'
          align='center'
          style={!isNumber(displayName) ? {textAlign: 'left'} : {}}
        >
          {displayName}
        </TableCell>
        <TableCell
          className='fw-semibold fs-6 table-cell-border'
          align='center'
          size='medium'
          style={!isNumber(visitors) ? {textAlign: 'center'} : {}}
        >
          {visitors.toLocaleString('en-IN')}

        </TableCell>
        <TableCell
          className='fw-semibold fs-6 table-cell-border'
          
          align='center'
          style={!isNumber(avg_daily_visitor) ? {textAlign: 'left'} : {}}
        >
          {Math.round(avg_daily_visitor).toLocaleString('en-IN')}
        </TableCell>
        <TableCell
          className='fw-semibold fs-6  table-cell-border'
          align='center'
          style={!isNumber(area) ? {textAlign: 'left'} : {}}
        >
          {area}
        </TableCell>
        <TableCell
          className='fw-semibold fs-6  table-cell-border'
          align='center'
          style={!isNumber(android_ios) ? {textAlign: 'center'} : {}}
        >
          {android_ios}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={activeRow === name} timeout='auto' unmountOnExit>
            <Box sx={{margin: 2}} className='border border-gray-300 border-1 '>
              <VisitorOriginTable subRowHeaders={subRowHeaders} subRow={subRow} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const PlaceStatisticsTable: React.FC<PlaceStatisticsTableProps> = ({
  poi_category,
  city,
  place_name,
  date,
  rowClickAndSetBounds,
  data,
}) => {
  const [usersData, setUsersData] = useState<User[]>([])
  const [subRowMap, setSubRowMap] = useState<any>({})
  const [sortOrder, setSortOrder] = useState<{[key: string]: 'asc' | 'desc'}>({
    name: 'asc',
    visitors: 'asc',
    avg_daily_visitor: 'asc',
    area: 'asc',
    android_ios: 'asc',
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [activeRow, setActiveRow] = useState('')
  const itemsPerPage = 8

  useEffect(() => {
    const fetchData = async () => {
      // const fetchDataForPlace = async (place: string) => {
      //   const requestData = {
      //     poi_category,
      //     city,
      //     place_name: [place],
      //     date,
      //   }
      //   try {
      //     const response = await addLoader(async () => await fetchPlaceStatistics(requestData))
      //     const visitorData = response.visitor[0]
      //     if (visitorData) {
      //       const {
      //         place_id,
      //         place_name,
      //         visitorcount,
      //         avgDailyVisitor,
      //         avgDistabce,
      //         'android/ios': android_ios,
      //       } = visitorData

      //       return {
      //         id: place_name,
      //         place_id,
      //         name: place_name,
      //         visitors: Number(visitorcount) || 0,
      //         avg_daily_visitor: Number(avgDailyVisitor) || 0,
      //         area: avgDistabce || 0,
      //         android_ios: Number(android_ios) || 0,
      //       }
      //     }
      //     return {
      //       id: place,
      //       name: place,
      //       place_id: -1,
      //       visitors: 0,
      //       avg_daily_visitor: 0,
      //       area: 0,
      //       android_ios: 0,
      //     }
      //   } catch (error) {
      //     console.error('Error fetching data for place:', place, error)
      //     return {
      //       id: place,
      //       name: place,
      //       place_id: -1,
      //       visitors: 0,
      //       avg_daily_visitor: 0,
      //       area: 0,
      //       android_ios: 0,
      //     }
      //   }
      // }

      const fetchAllPlaceData = async () => {
        if (data) {
          setUsersData(data?.filter((result) => result !== null) as User[])
          return
        }
        // const promises = place_name.map((place) => fetchDataForPlace(place))
        // const results = await Promise.all(promises)
        // setUsersData(results.filter((result) => result !== null) as User[])
      }

      fetchAllPlaceData()
    }
    fetchData()
  }, [data])

  const handleSort = (column: keyof User) => {
    const sortedData = [...usersData].sort((a, b) => {
      if (sortOrder[column] === 'asc') {
        return a[column] > b[column] ? 1 : -1
      } else {
        return a[column] < b[column] ? 1 : -1
      }
    })
    setUsersData(sortedData)
    setSortOrder((prevState) => ({
      ...prevState,
      [column]: prevState[column] === 'asc' ? 'desc' : 'asc',
    }))
  }

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage

  const filteredUsersData = usersData.filter(
    (user) => user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const currentItems = filteredUsersData.slice(indexOfFirstItem, indexOfLastItem)

  const totalPages = Math.ceil(filteredUsersData.length / itemsPerPage)

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const renderPagination = (): JSX.Element[] => {
    let pages: JSX.Element[] = []
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button onClick={() => handlePageChange(i)} className='page-link'>
            {i}
          </button>
        </li>
      )
    }

    if (totalPages > 3) {
      pages = [
        <li key='prev' className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button onClick={() => handlePageChange(currentPage - 1)} className='page-link'>
            <KTIcon iconName='left' className='fs-3 ms-1' />
          </button>
        </li>,
        pages[0],
        pages[1],
        <li key='dots' className='page-item disabled'>
          <span className='page-link'>...</span>
        </li>,
        pages[totalPages - 1],
        <li key='next' className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button onClick={() => handlePageChange(currentPage + 1)} className='page-link'>
            <KTIcon iconName='right' className='fs-3 ms-1' />
          </button>
        </li>,
      ]
    } else if (totalPages === 3) {
      pages = [
        <li key='prev' className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button onClick={() => handlePageChange(currentPage - 1)} className='page-link'>
            <KTIcon iconName='left' className='fs-3 ms-1' />
          </button>
        </li>,
        pages[0],
        pages[1],
        pages[2],
        <li key='next' className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button onClick={() => handlePageChange(currentPage + 1)} className='page-link'>
            <KTIcon iconName='right' className='fs-3 ms-1' />
          </button>
        </li>,
      ]
    } else if (totalPages === 2) {
      pages = [
        <li key='prev' className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button onClick={() => handlePageChange(currentPage - 1)} className='page-link'>
            <KTIcon iconName='left' className='fs-3 ms-1' />
          </button>
        </li>,
        pages[0],
        pages[1],
        <li key='next' className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button onClick={() => handlePageChange(currentPage + 1)} className='page-link'>
            <KTIcon iconName='right' className='fs-3 ms-1' />
          </button>
        </li>,
      ]
    } else {
      pages = [
        <li key='prev' className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button onClick={() => handlePageChange(currentPage - 1)} className='page-link'>
            <KTIcon iconName='left' className='fs-3 ms-1' />
          </button>
        </li>,
        pages[0],
        <li key='next' className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button onClick={() => handlePageChange(currentPage + 1)} className='page-link'>
            <KTIcon iconName='right' className='fs-3 ms-1' />
          </button>
        </li>,
      ]
    }

    return pages
  }

  const csvData = filteredUsersData.map((user) => ({
    id: user.id,
    name: user.name,
    visitors: user.visitors,
    avg_daily_visitor: user.avg_daily_visitor,
    area: user.area,
    android_ios: user.android_ios,
  }))

  const rowClicked = (name) => {
    rowClickAndSetBounds(name)
    if (activeRow !== name) setActiveRow(name)
    else setActiveRow('')
  }

  return (
    <>
      <TableContainer component={Paper} className='table-container'>
        <div className='stickyHeader'>
          <label className='label'>Visitors</label>
          <TextField
            className='stickyHeader-text-field'
            label='Search Place'
            variant='outlined'
            size='small' 
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          {/* Include CSVLink component content here */}
        </div>
        <div
          style={{
            overflowY: 'auto',
            height: '620px',
          }}
        >
          <Table aria-label='collapsible table' style={{position: 'relative'}}>
            <TableHead
              style={{
                position: 'sticky',
                top: '0',
                backgroundColor: '#F0F8FF',
                zIndex: '50',
                outline: '1px rgba(224, 224, 224, 1) solid',
              }}
            >
              <TableRow>
                <TableCell className='table-cell-border' />
                <TableCell
                  className='fw-bold fs-6 font-sans table-cell-border'
                  style={{color: '#8990aa'}}
                  onClick={() => handleSort('name')}
                >
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    Place Name
                    {sortOrder.name === 'asc' ? (
                      <KTIcon iconName='arrow-up' className='fs-3 ms-1' />
                    ) : (
                      <KTIcon iconName='arrow-down' className='fs-3 ms-1' />
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  className='fw-bold fs-6 font-sans table-cell-border'
                  align='center'
                  onClick={() => handleSort('visitors')}
                  style={{color: '#8990aa'}}
                >
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    Total Visitors
                    {sortOrder.visitors === 'asc' ? (
                      <KTIcon iconName='arrow-up' className='fs-3 ms-1' />
                    ) : (
                      <KTIcon iconName='arrow-down' className='fs-3 ms-1' />
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  className='fw-bold fs-6 font-sans table-cell-border'
                  align='center'
                  onClick={() => handleSort('avg_daily_visitor')}
                  style={{color: '#8990aa'}}
                >
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    Avg Daily Visitor
                    {sortOrder.avg_daily_visitor === 'asc' ? (
                      <KTIcon iconName='arrow-up' className='fs-3 ms-1' />
                    ) : (
                      <KTIcon iconName='arrow-down' className='fs-3 ms-1' />
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  className='fw-bold fs-6 font-sans table-cell-border'
                  align='center'
                  onClick={() => handleSort('area')}
                  style={{color: '#8990aa'}}
                >
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    Avg Distance
                    {sortOrder.area === 'asc' ? (
                      <KTIcon iconName='arrow-up' className='fs-3 ms-1' />
                    ) : (
                      <KTIcon iconName='arrow-down' className='fs-3 ms-1' />
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  className='fw-bold fs-6 font-sans table-cell-border'
                  align='center'
                  onClick={() => handleSort('android_ios')}
                  style={{color: '#8990aa'}}
                >
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    Android:IOS
                    {sortOrder.android_ios === 'asc' ? (
                      <KTIcon iconName='arrow-up' className='fs-3 ms-1' />
                    ) : (
                      <KTIcon iconName='arrow-down' className='fs-3 ms-1' />
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentItems.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  rowClickAndSetBounds={rowClicked}
                  activeRow={activeRow}
                />
              ))}
            </TableBody>
          </Table>
        </div>
        <ul
          className='pagination py-4'
          style={{position: 'sticky', bottom: 0, backgroundColor: '#fff', zIndex: 1}}
        >
          {renderPagination()}
        </ul>
      </TableContainer>
    </>
  )
}

export default PlaceStatisticsTable
