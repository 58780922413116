import React, {useEffect, useState} from 'react'
import {fetchWidgetsData, ApiCardsResponseData} from '../../service/apiService'
import {FiltersState} from './Interfaces'
import {KTIcon} from '../../../../_metronic/helpers'
import {isNumber} from '../../utils'

interface WidgetsProps {
  filters: FiltersState
  data: any
}

const initialData: any = {
  total: 0,
  averaga_distance: 0,
  visitors: 0,
  'android/ios': '0:0',
}

const WidgetCard: React.FC<{
  title: string
  subTitle?: string
  value: string | number
  bgColor: string
  unit?: string
  secondaryColor: string
}> = ({title, value, bgColor, unit, subTitle, secondaryColor}) => {
  const cardStyle = {}

  return (
    <div className='col' style={{}}>
      <div
        className='rounded p-4 py-5 shadow-xs'
        style={{backgroundColor: bgColor, height: '75px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'}}
      >
        <div className='d-flex  ' style={cardStyle}>
          <div style={{width: '1.8rem', marginRight: '2rem', display: 'flex', alignItems: 'center'}}>
            <KTIcon
              iconName='abstract-26'
              className='fs-2x ms-1 '
              style={{color: secondaryColor}}
            />
          </div>
          <div className='d-flex  justify-content-between' style={{flex: '1'}}>
            <div className=''>
              <div className='mb-auto'>
                <div className='fw-bold fs-5 font-sans text-gray-900'>{title}</div>
              </div>

              <div className='mt-auto'>
                <div className='fw-semibold font-sans text-gray-700'>{subTitle}</div>
              </div>
            </div>
            <div className='my-2' style={{display: 'flex', alignItems: 'center'}}>
              <div className='fw-bold fs-2 font-sans' style={{color: secondaryColor}}>
                {isNumber(value) ? Number(value)?.toLocaleString() : value}
                {unit && ` ${unit}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Widgets: React.FC<WidgetsProps> = ({filters, data: widgets}) => {
  const [data, setData] = useState(initialData)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  useEffect(() => {
    const fetchData = async () => {
      const {poi_category, city, place_name, date} = filters

      setLoading(true)
      try {
        const response = widgets
        // ||
        // (await addLoader(
        //   async () => await fetchWidgetsData({poi_category, city, place_name, date})
        // ))
        setData(response)
      } catch (error) {
        setError('Error fetching data')
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    if (filters.place_name.length > 0) {
      fetchData()
    } else {
      setData(initialData)
    }
  }, [widgets])

  const formatDistance = (distance: number | null) =>
    distance !== null ? distance?.toFixed(2) : '0.00'
  const formatVisitors = (visitors: number | string | null) => (visitors !== null ? visitors : '0')
  const formatAndroidIOS = (ratio: string | null) => {
    if (ratio) {
      const [android, ios] = ratio.split(':').map(Number)
      const total = android + ios
      if (total === 0) {
        return '0% : 0%'
      } else {
        const androidPercentage = ((android / total) * 100).toFixed(2)
        const iosPercentage = ((ios / total) * 100).toFixed(2)
        return `${androidPercentage}% : ${iosPercentage}%`
      }
    }
    return '0% : 0%'
  }

  return (
    <div className=''>
      <div className='row g-5 mb-3'>
        <WidgetCard
          title='Total Places'
          subTitle={'Total Places'.split(' ')[1]}
          value={loading ? '0' : data?.total}
          bgColor='#F5EAC3'
          secondaryColor='#FFC600'
        />
        <WidgetCard
          title='Total Visitors'
          subTitle={'Total Visitors'.split(' ')[1]}
          value={loading ? '0' : formatVisitors(data?.visitors)}
          bgColor='#C3FBD6'
          secondaryColor='#28b959'
        />
        <WidgetCard
          title='Average Distance'
          subTitle={'Average Distance'.split(' ')[1]}
          value={loading ? '0 km' : formatDistance(data?.averaga_distance)}
          unit='km'
          bgColor='#FFE0EA'
          secondaryColor='#FD1862'
        />
        <WidgetCard
          title='Android:IOS'
          subTitle={'percentage'}
          value={
            loading
              ? '0% : 0%'
              : data?.['android'] && data?.['ios']
              ? `${data?.['android']} : ${data?.['ios']}`
              : '0% : 0%'
          }
          bgColor='#E7DEFD'
          secondaryColor='#5616F5'
        />
      </div>
    </div>
  )
}

export default Widgets
