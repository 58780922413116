import React, {useEffect, useState} from 'react'
import BarChart from './components/BarChart'
import HeatMap from './components/Map'
import Weekly_Daily_Visitor_Line_Graph from './components/Weekly_Daily_Visitor_Line_Graph'
import Hourly_Trend_Line_Graph from './components/Hourly_Trend_Line_Graph'
import Widgets from './components/Widgets'
import Filters from './components/Filters'
import PlaceStatisticsTable from './components/Place_Statistics_Table'
import {FiltersState} from './components/Interfaces'
import mapboxgl from 'mapbox-gl'
import {ClipLoader} from 'react-spinners'
import {KTIcon} from '../../../_metronic/helpers'
import {
  DailyVisitorCountResponse,
  MonthlyVisitorCountResponse,
  fetchAggData,
  fetchAggWeeklyTrend,
  fetchChoropleth,
  fetchCompData,
  fetchCompWeeklyTrend,
  fetchDailyVisitorCount,
  fetchMallDetails,
  fetchMonthlyVisitorCount,
  fetchPlaceStatistics,
  fetchVisitorOrigin,
  fetchWidgetsData,
} from '../service/apiService'
import Loader from '../../../_metronic/helpers/loader'
import {features} from 'process'

const Dashboard = () => {
  const [map, setMap] = useState<mapboxgl.Map | null>(null)
  const [defualtBounds, setDefualtBounds] = useState<any>([])
  const [locationMap, setLocationMap] = useState({})
  const [previouslyClickedRow, setPreviouslyClickedRow] = useState(-1)
  const [loading, isLoading] = useState(false)
  const [stickyFilters, setStickyFilters] = useState(true)
  const [filters, setFilters] = useState<FiltersState>({
    poi_category: '',
    city: '',
    place_name: [],
    date: [],
    year: '',
  })
  const [showLocalityChoropleth, setShowLocalityChoropleth] = useState(true)
  const [fetchedData, setFetchedData] = useState<any>({})
  const [choroplethDataToShow, setChoroplethDataToShow] = useState<any>()
  const [choroplethPointDataToShow, setChoroplethPointDataToShow] = useState<any>()
  const setFilterData = (newFilters: FiltersState) => {
    setFilters(newFilters)
  }

  useEffect(() => {
    const loadAllData = async () => {
      isLoading(true)
      await delay(100)
      const allData = await fetchAllData(filters, isLoading)

      if (allData) {
        setPreviouslyClickedRow(-1)
        const filteredChoroplethData = filterChoroplethData(allData?.choroplethData)
        setChoroplethDataToShow(filteredChoroplethData)
        if (allData?.mallDetails) {
          setChoroplethPointDataToShow({...allData?.mallDetails})
        }
        setFetchedData(allData)
        // Do something with allData
      }
      isLoading(false)
    }

    if (filters) {
      loadAllData()
    }
  }, [filters])

  // console.log("dashboard", filters)
  const filterChoroplethData = (data, place_name = undefined) => {
    if (!data) return {}

    const filteredFeatures = place_name
      ? data.features.filter((item) => item.properties.place_name === place_name)
      : data.features

    const aggregatedData = filteredFeatures.reduce((acc, feature) => {
      const locality_id = feature.properties.locality_id
      const visitors = feature.properties.visitors
      const visitor_proportion = feature.properties.visitor_proportion

      // Convert visitor_proportion to a number
      const proportionValue = parseFloat(visitor_proportion.replace('%', ''))

      if (!acc[locality_id]) {
        acc[locality_id] = {
          ...feature,
          properties: {
            ...feature.properties,
            visitors: visitors,
            visitor_proportion: `${proportionValue.toFixed(2)}%`, // Store as a number for now
          },
        }
      } else {
        acc[locality_id].properties.visitors += visitors
        const newProportion =
          parseFloat(acc[locality_id].properties.visitor_proportion.replace('%', '')) +
          proportionValue
        acc[locality_id].properties.visitor_proportion = `${newProportion.toFixed(2)}%`
      }

      return acc
    }, {})

    return {
      ...data,
      features: Object.values(aggregatedData),
    }
  }

  const makeChoroplethPointData = (selection = undefined) => {
    if (fetchedData?.mallDetails) {
      const filteredPointData = selection
        ? {
            type: 'FeatureCollection',
            features: fetchedData?.mallDetails?.features.filter(
              (item) => item?.properties?.place_name === selection
            ),
          }
        : fetchedData?.mallDetails
      setChoroplethPointDataToShow(filteredPointData)
    }
  }

  const rowClickAndSetBounds = (place_name) => {
    if (map && locationMap[place_name]) {
      if (previouslyClickedRow !== place_name) {
        const offset = 0.01
        const bounds = new mapboxgl.LngLatBounds(
          [locationMap[place_name][0] - offset, locationMap[place_name][1] - offset],
          [locationMap[place_name][0] + offset, locationMap[place_name][1] + offset]
        )
        const filteredChoroplethData = filterChoroplethData(fetchedData?.choroplethData, place_name)
        setChoroplethDataToShow(filteredChoroplethData)
        map.fitBounds(bounds)
        makeChoroplethPointData(place_name)
        setPreviouslyClickedRow(place_name)
      } else {
        const offset = 0.01
        const bounds = new mapboxgl.LngLatBounds(
          [defualtBounds[0] - offset, defualtBounds[1] - offset],
          [defualtBounds[2] + offset, defualtBounds[3] + offset]
        )
        setPreviouslyClickedRow(-1)
        makeChoroplethPointData()
        const filteredChoroplethData = filterChoroplethData(fetchedData?.choroplethData)
        setChoroplethDataToShow(filteredChoroplethData)
        map.fitBounds(bounds)
      }
    }
  }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  return (
    <div style={{position: 'relative'}}>
      {loading && <Loader />}

      <div
        className='filter-wrapper'
        style={
          stickyFilters
            ? {position: 'sticky', top: '6rem', backgroundColor: 'white', zIndex: 500}
            : {}
        }
      >
        <div style={{position: 'absolute', right: '1rem', top: '0.8rem'}}>
          {stickyFilters ? (
            <KTIcon
              iconName='down'
              className='fs-2x ms-1 '
              onClick={() => setStickyFilters((previous) => !previous)}
            />
          ) : (
            <KTIcon
              iconName='up'
              className='fs-2x ms-1 '
              onClick={() => setStickyFilters((previous) => !previous)}
            />
          )}
        </div>
        <Filters setFilterData={setFilterData} />
      </div>
      <div className=''>
        <div className='mt-8 mb-8'>
          <Widgets filters={filters} data={fetchedData?.widgets} />
        </div>
      </div>
      <div className='row mb-8'>
        <div className='col-md-6'>
          <HeatMap
            filters={filters}
            map={map}
            setMap={setMap}
            locationMap={locationMap}
            setLocationMap={setLocationMap}
            setDefualtBounds={setDefualtBounds}
            data={fetchedData?.mallDetails}
            choroplethData={choroplethDataToShow}
            choroplethPointData={choroplethPointDataToShow}
            showLocalityChoropleth={showLocalityChoropleth}
            setShowLocalityChoropleth={setShowLocalityChoropleth}
          />
        </div>
        <div className='col-md-6'>
          <PlaceStatisticsTable
            poi_category={filters.poi_category}
            city={filters.city}
            place_name={filters.place_name}
            date={filters.date}
            rowClickAndSetBounds={rowClickAndSetBounds}
            data={fetchedData?.placeData}
          />
        </div>
      </div>
      <div className='row mb-8'>
        <div className='col-md-8'>
          <Hourly_Trend_Line_Graph
            filters={filters}
            data={{aggData: fetchedData?.aggData, compData: fetchedData?.compData}}
          />
        </div>
        <div className='col-md-4'>
          <Weekly_Daily_Visitor_Line_Graph
            filters={filters}
            data={{aggData: fetchedData?.aggWeeklyTrend, compData: fetchedData?.compWeeklyTrend}}
          />
        </div>
      </div>
      <div className='row mb-8'>
        <div className='col-md-12'>
          <BarChart filters={filters} />
        </div>
      </div>
    </div>
  )
}

const fetchWidgetsDataWrapper = async (requestData) => {
  try {
    return await fetchWidgetsData(requestData)
  } catch (error) {
    console.error('Error fetching wedgets data:', error)
    return null
  }
}

const fetchAggWeeklyTrendData = async (requestData) => {
  try {
    return await fetchAggWeeklyTrend(requestData)
  } catch (error) {
    console.error('Error fetching agg weekly trend data:', error)
    return null
  }
}

const fetchCompWeeklyTrendData = async (requestData) => {
  try {
    return await fetchCompWeeklyTrend(requestData)
  } catch (error) {
    console.error('Error fetching comp weekly trend data:', error)
    return null
  }
}

const fetchDailyVisitorCountData = async (requestData) => {
  try {
    return await fetchDailyVisitorCount(requestData)
  } catch (error) {
    console.error('Error fetching daily visitor count data:', error)
    return null
  }
}

const fetchAggDataData = async (requestData) => {
  try {
    return await fetchAggData(requestData)
  } catch (error) {
    console.error('Error fetching agg data:', error)
    return null
  }
}

const fetchCompDataData = async (requestData) => {
  try {
    return await fetchCompData(requestData)
  } catch (error) {
    console.error('Error fetching comp data:', error)
    return null
  }
}

const fetchMallDetailsData = async (requestData) => {
  try {
    return await fetchMallDetails(requestData)
  } catch (error) {
    console.error('Error fetching mall details:', error)
    return null
  }
}

const fetchPlaceStatisticsData = async (requestData) => {
  try {
    return await fetchPlaceStatistics(requestData)
  } catch (error) {
    console.error('Error fetching place statistics:', error)
    return null
  }
}

const fetchVisitorOriginData = async (body) => {
  try {
    return await fetchVisitorOrigin(body)
  } catch (error) {
    console.error('Error fetching visitor origin data:', error)
    return null
  }
}
const fetchDataForPlace = async (place, filters) => {
  const requestData = {
    poi_category: filters.poi_category,
    city: filters.city,
    start_date: filters.start_date,
    end_date: filters.end_date,
    place_name: [place],
    date: filters.date,
  }

  try {
    const response = await fetchPlaceStatisticsData(requestData)
    const visitorData = response?.visitor?.[0]
    if (visitorData) {
      const {place_id, place_name, visitorcount, avgDailyVisitor, avgDistabce, android_ios} =
        visitorData

      const visitorOriginData = await fetchVisitorOriginData({
        place_id,
        poi_category: filters.poi_category,
        city: filters.city,
        date: filters.date,
        start_date: filters.start_date,
        end_date: filters.end_date,
      })

      return {
        id: place_name,
        place_id,
        name: place_name,
        visitors: Number(visitorcount) || 0,
        avg_daily_visitor: Number(avgDailyVisitor) || 0,
        area: avgDistabce || 0,
        android_ios,
        visitorOriginData,
      }
    }
  } catch (error) {
    console.error('Error fetching data for place:', place, error)
  }

  return {
    id: place,
    name: place,
    place_id: -1,
    visitors: 0,
    avg_daily_visitor: 0,
    area: 0,
    android_ios: 0,
    visitorOriginData: null,
  }
}

const fetchAllPlaceData = async (filters) => {
  const promises = filters.place_name.map((place) => fetchDataForPlace(place, filters))
  const results = await Promise.all(promises)
  return results
}

const fetchAllData = async (filters, isLoading) => {
  if (!filters) return
  const requestData = {
    poi_category: filters.poi_category,
    city: filters.city,
    start_date: filters.start_date,
    end_date: filters.end_date,
    date: filters.date,
  }
  console.log(requestData)
  if (
    !requestData ||
    Object.values(requestData).every((item) =>
      Array.isArray(item) ? item.length === 0 : item === ''
    )
  ) {
    return
  }
  try {
    const [
      aggWeeklyTrendResponse,
      compWeeklyTrendResponse,
      dailyVisitorCountResponse,
      aggDataResponse,
      compDataResponse,
      mallDetailsResponse,
      placeData,
      fetchWidgetsResponse,
      fetchChoroplethResponse,
    ] = await Promise.all([
      fetchAggWeeklyTrendData(requestData),
      fetchCompWeeklyTrendData(requestData),
      fetchDailyVisitorCountData(requestData),
      fetchAggDataData(requestData),
      fetchCompDataData(requestData),
      fetchMallDetailsData(requestData),
      fetchAllPlaceData(filters),
      fetchWidgetsDataWrapper(requestData),
      fetchChoropleth(requestData),
    ])

    return {
      aggWeeklyTrend: aggWeeklyTrendResponse?.visitor,
      compWeeklyTrend: compWeeklyTrendResponse?.visitor,
      dailyVisitorCount: dailyVisitorCountResponse?.DailyVisitorCount,
      aggData: aggDataResponse?.visitor,
      compData: compDataResponse?.visitor,
      mallDetails: mallDetailsResponse?.malls,
      placeData,
      widgets: fetchWidgetsResponse?.visitor,
      choroplethData: fetchChoroplethResponse?.choroplethData,
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    return null
  }
}

export default Dashboard
