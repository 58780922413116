import axios from 'axios'
const API_HOST = process.env.REACT_APP_API_URL
// console.log(API_HOST);

const GET_PLACE_STATISTICS_URL = API_HOST + '/get-table'
const API_URL_CARDS = API_HOST + '/get-cards'
const AGG_API_URL = API_HOST + '/get-aggregate-hourlytrend'
const COMP_API_URL = API_HOST + '/get-hourlytrend'
const MONTHLY_VISITOR_COUNT_URL = API_HOST + '/get-monthly-visitor-count'
const DAILY_VISITOR_COUNT_URL = API_HOST + '/get-daily-visitor-count'
const WEEKLY_VISITOR_COUNT_URL = API_HOST + '/get-weekly-visitor-count'
const AGG_WEEKLY_TREND_URL = API_HOST + '/get-aggregate-weeklytrend'
const COMP_WEEKLY_TREND_URL = API_HOST + '/get-weeklytrend'
const GET_INPUT_URL = API_HOST + '/get-input'
const GET_MALLS_BY_CITY_URL = API_HOST + '/get-places'
const GET_MALL_DETAILS_URL = API_HOST + '/get-mall-details'
const GET_VISITOR_ORIGIN = API_HOST + '/get-visitor-origin'
const GET_CHOROPLETH = API_HOST + '/get-choropleth'

export interface ApiRequestData {
  poi_category: string
  city: string
  place_name: string[]
  date: string[]
}

interface PlaceStatisticsRequest {
  poi_category: string
  city: string
  place_name: string[]
  date: string[]
}

export interface PlaceStatisticsResponse {
  message: string
  visitor: {
    place_id: string
    place_name: string
    visitorcount: string
    avgDailyVisitor: string
    avgDistabce: number
    android_ios: string
  }[]
}

export interface ApiCardsResponseData {
  message: string
  visitor: {
    total: number
    averaga_distance: number
    visitors: number
    'android/ios': string
  }
}

interface AggResponseData {
  message: string
  visitor: number[]
}

interface CompResponseData {
  message: string
  visitor: {
    name: string
    count: number[]
  }[]
}

export interface MonthlyVisitorCountResponse {
  message: string
  MonthlyVisitorCount: {
    year: string
    month: string
    visitors: number
  }[]
}

interface MonthlyVisitorCountRequestData {
  place_name: string[]
  poi_category: string
  city: string
  date: string[]
}

export interface DailyVisitorCountResponse {
  message: string
  DailyVisitorCount: {
    date: string
    visitorcout: string
  }[]
}

interface DailyVisitorCountRequestData {
  place_name: string[]
  // year: string;
  poi_category: string
  city: string
  date: string[]
}

export interface WeeklyVisitorCountResponse {
  message: string
  WeeklyVisitorCount: {
    [key: string]: {
      week: string
      Visitor: number
    }[]
  }
}

interface WeeklyVisitorCountRequestData {
  place_name: string[]
  month: number
  date: string[]
  city: string
  poi_category: string
}

export interface FiltersState {
  poi_category: string
  city: string
  place_name: string[]
  date: string[]
}

export interface WeeklyTrendResponse {
  message: string
  visitor: {
    data: string[]
    visitor: number[]
  }
}

export interface CompWeeklyTrendResponse {
  message: string
  visitor: {
    name: string
    data: string[]
    count: number[]
  }[]
}

interface FilterResponse {
  message: string
  city: {city: string}[]
  category: {category: string}[]
  place_name: {place_name: string}[]
}

interface MallDetailsRequest {
  poi_category: string
  city: string
  place_name: string[]
}

export interface MallDetailsResponse {
  message: string
  malls: {
    type: 'FeatureCollection'
    features: {
      type: 'Feature'
      properties: {
        place_id: number
        place_name: string
        locality: string
        visits: number
        frequency: number
        visitorOriginProportion: number
      }
      geometry: {
        type: 'Point'
        coordinates: number[]
      }
    }[]
  }
}

export interface OptionType {
  value: string
  label: string
}

export interface VisitorOrigin {
  place_id: string
  city: string
  locality: string
  visitors: number
}

const formatPlaceName = (name: string) => {
  return name.replace(/_/g, ' ')
}

// Fetch place statistics based on filters
export const fetchPlaceStatistics = async (
  data: PlaceStatisticsRequest
): Promise<PlaceStatisticsResponse> => {
  try {
    const response = await axios.post<PlaceStatisticsResponse>(GET_PLACE_STATISTICS_URL, data)
    return response.data
  } catch (error) {
    console.error('Error fetching place statistics:', error)
    throw error
  }
}

// Fetch card data for widgets based on filters
export const fetchWidgetsData = async (data: ApiRequestData): Promise<ApiCardsResponseData> => {
  const response = await axios.post<ApiCardsResponseData>(API_URL_CARDS, data)
  return response.data
}

// Fetch aggregated hourly trend data
export const fetchAggData = async (data: ApiRequestData): Promise<AggResponseData> => {
  const response = await axios.post<AggResponseData>(AGG_API_URL, data)
  return response.data
}

// Fetch comparative hourly trend data
export const fetchCompData = async (data: ApiRequestData): Promise<CompResponseData> => {
  const response = await axios.post<CompResponseData>(COMP_API_URL, data)
  return response.data
}

// Fetch monthly visitor count data
export const fetchMonthlyVisitorCount = async (
  requestData: MonthlyVisitorCountRequestData
): Promise<MonthlyVisitorCountResponse> => {
  const response = await axios.post<MonthlyVisitorCountResponse>(
    MONTHLY_VISITOR_COUNT_URL,
    requestData
  )
  return response.data
}

// Fetch daily visitor count data
export const fetchDailyVisitorCount = async (
  requestData: DailyVisitorCountRequestData
): Promise<DailyVisitorCountResponse> => {
  try {
    const response = await axios.post<DailyVisitorCountResponse>(
      DAILY_VISITOR_COUNT_URL,
      requestData
    )
    return response.data
  } catch (error) {
    console.error('Error fetching daily visitor count:', error)
    throw error
  }
}

// Fetch weekly visitor count data
export const fetchWeeklyVisitorCount = async (
  requestData: WeeklyVisitorCountRequestData
): Promise<WeeklyVisitorCountResponse> => {
  try {
    const response = await axios.post<WeeklyVisitorCountResponse>(
      WEEKLY_VISITOR_COUNT_URL,
      requestData
    )
    return response.data
  } catch (error) {
    console.error('Error fetching weekly visitor count:', error)
    throw error
  }
}

// Fetch aggregated weekly trend data
export const fetchAggWeeklyTrend = async (data: FiltersState): Promise<WeeklyTrendResponse> => {
  const response = await axios.post<WeeklyTrendResponse>(AGG_WEEKLY_TREND_URL, data)
  return response.data
}

// Fetch comparative weekly trend data
export const fetchCompWeeklyTrend = async (
  data: FiltersState
): Promise<CompWeeklyTrendResponse> => {
  const response = await axios.post<CompWeeklyTrendResponse>(COMP_WEEKLY_TREND_URL, data)
  return response.data
}

// Fetch malls by city
export const fetchMallsByCity = async (
  city: string,
  poi_category: string[]
): Promise<OptionType[]> => {
  try {
    const response = await axios.post<{message: string; malls: {place_name: string}[]}>(
      GET_MALLS_BY_CITY_URL,
      {poi_category: poi_category[0], city} // TODO change [0]
    )
    const fetchedPlaces = response.data.malls.map((item) => ({
      value: item.place_name,
      label: formatPlaceName(item.place_name),
    }))
    return fetchedPlaces
  } catch (error) {
    console.error('Error fetching malls by city:', error)
    throw error
  }
}

// Fetch filter data (city, category, place_name)
export const fetchFilterData = async (): Promise<FilterResponse> => {
  try {
    const response = await axios.post<FilterResponse>(GET_INPUT_URL)
    return response.data
  } catch (error) {
    console.error('Error fetching filter data:', error)
    throw error
  }
}

// Fetch mall details
export const fetchMallDetails = async (
  requestData: MallDetailsRequest
): Promise<MallDetailsResponse> => {
  try {
    const response = await axios.post<MallDetailsResponse>(GET_MALL_DETAILS_URL, requestData)
    return response.data
  } catch (error) {
    console.error('Error fetching mall details:', error)
    throw error
  }
}

// Fetch visitor origin details
export const fetchVisitorOrigin = async (requestData: any): Promise<VisitorOrigin | undefined> => {
  try {
    const response = await axios.post<any>(GET_VISITOR_ORIGIN, requestData)
    return response.data?.visitorOrigin
  } catch (error) {
    console.error('Error fetching mall details:', error)
    return
  }
}

// Fetch visitor origin details
export const fetchChoropleth = async (requestData: any): Promise<any> => {
  try {
    const response = await axios.post<any>(GET_CHOROPLETH, requestData)
    return response.data
  } catch (error) {
    console.error('Error fetching mall details:', error)
    return
  }
}
