import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {MutableRefObject, useState} from 'react'

type PropsType = {
  sidebarRef: ['']
}

const SidebarLogo = ({isHovered}) => {
  const {config} = useLayout()
  const [isMinimized, setIsMinimized] = useState(
    config.app?.sidebar?.default?.minimize?.desktop?.default || false
  )
  const [isMaximized, setIsMaximized] = useState(false)

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled

  const minimizeSidebar = () => {
    setIsMinimized(true)
    setIsMaximized(false)
    document.body.setAttribute('data-kt-app-sidebar-minimize', 'on')
  }

  const maximizeSidebar = () => {
    setIsMinimized(false)
    setIsMaximized(true)
    document.body.setAttribute('data-kt-app-sidebar-minimize', 'off')
  }

  return (
    <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo'>
      <Link to='/dashboard'>
        {config.layoutType === 'dark-sidebar' &&
        (appSidebarDefaultMinimizeDesktopEnabled === true ||
          appSidebarDefaultCollapseDesktopEnabled === true) ? (
          <div className='d-flex app-sidebar-logo-default h-25px'>
            <img
              src={toAbsoluteUrl('assets/media/avatars/logo.png')}
              alt=''
              className='rounded-circle shadow-sm me-2'
              style={{width: '30px', height: '30px'}}
            />
            <div className={`icon-header ${isHovered ? ' visible ' : ' hidden '}`}>
              <p className='text-primary fs-1 fw-bolder mb-4'>Mobility</p>
              <p className='text-white fs-1 fw-bolder mb-4'>Dashboard</p>
            </div>
          </div>
        ) : (
          <>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/default.svg')}
              className='h-25px app-sidebar-logo-default theme-light-show'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/default-dark.svg')}
              className='h-25px app-sidebar-logo-default theme-dark-show'
            />
          </>
        )}
      </Link>

      {/* {appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled ? (
        <div
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary h-30px w-30px position-absolute top-50 start-100 translate-middle',
            {active: isMinimized}
          )}
        >
          {isMinimized ? (
            <KTIcon iconName='black-right-line' className='fs-3 ms-1' onClick={maximizeSidebar} />
          ) : (
            <KTIcon iconName='black-left-line' className='fs-3 ms-1' onClick={minimizeSidebar} />
          )}
        </div>
      ) : null} */}
    </div>
  )
}

export {SidebarLogo}
