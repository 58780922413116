export const convertNameString = (str: string) => {
  if (!str) return str
  const converteStr = str.replace(/_/g, ' ')
  const capitalisedStr = converteStr.charAt(0).toUpperCase() + converteStr.slice(1)
  return capitalisedStr
}

export function isNumber(value: any): boolean {
  if (typeof value === 'string') {
    // Check if the string is a valid number
    return !isNaN(value as any) && !isNaN(parseFloat(value))
  }
  return typeof value === 'number' && !isNaN(value)
}

export const convertAllFeatureProperties = (feature) => {
  if (!feature) return
  try {
    if (feature.properties) {
      for (const prop in feature.properties) {
        if (Object.prototype.hasOwnProperty.call(feature.properties, prop)) {
          // Convert each property here as needed
          if (!isNumber(feature.properties[prop])) {
            feature.properties[prop] = convertNameString(feature.properties[prop])
          }
          // Add more conversions as necessary for other properties
        }
      }
    }
  } catch (error) {
    console.log('Error while converting properties', error)
  }
  return feature
}
