import React, {useState, useEffect, FC} from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import Select, {MultiValue, SingleValue, StylesConfig} from 'react-select'
import {fetchFilterData, fetchMallsByCity, OptionType} from '../../service/apiService'
import {DatePicker, InputGroup} from 'rsuite'

interface FiltersProps {
  setFilterData: (filters: {
    poi_category: string
    city: string
    place_name: string[]
    date: string[]
    year: string
    start_date: string | undefined
    end_date: string | undefined
  }) => void
}

const formatPlaceName = (name: string) => {
  return name.replace(/_/g, ' ')
}

const Filters: React.FC<FiltersProps> = ({setFilterData}) => {
  const [poiCategories, setPoiCategories] = useState<OptionType[]>([])
  const [cities, setCities] = useState<OptionType[]>([])
  const [places, setPlaces] = useState<OptionType[]>([])
  const [dateRange, setDateRange] = useState<string[]>([])
  const defaultStartDate = new Date().toISOString()
  const defaultEndDate = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()

  const [startDate, setStartDate] = useState<Date | null>(new Date(defaultStartDate))
  const [endDate, setEndDate] = useState<Date | null>(new Date(defaultEndDate))

  const [selectedPOI, setSelectedPOI] = useState<MultiValue<OptionType>>([])
  const [selectedCity, setSelectedCity] = useState<OptionType | null>(null)
  const [selectedPlace, setSelectedPlace] = useState<MultiValue<OptionType>>([])
  const [cityMalls, setCityMalls] = useState<OptionType[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchFilterData()

        const fetchedPoiCategories = response.category.map((item) => ({
          value: item.category,
          label: item.category,
        }))
        const fetchedCities = response.city.map((item) => ({
          value: item.city,
          label: item.city,
        }))
        const fetchedPlaces = response.place_name.map((item) => ({
          value: item.place_name,
          label: formatPlaceName(item.place_name),
        }))

        setPoiCategories(fetchedPoiCategories)
        setCities(fetchedCities)
        setPlaces(fetchedPlaces)

        if (fetchedPoiCategories.length > 0) {
          setSelectedPOI([fetchedPoiCategories[0]])
        }
        if (fetchedCities.length > 0) {
          setSelectedCity(fetchedCities[0])
        }

        // const defaultStartDate = '2024-03-03';
        // setStartDate(defaultStartDate)
        // const defaultEndDate = '2024-03-04';
        // setEndDate(defaultEndDate)
        const dates = getDatesInRange(defaultStartDate, defaultEndDate)
        setDateRange(dates)
        console.log(dates)

        handleFilterChange(fetchedCities[0], [fetchedPoiCategories[0]], dates, fetchedPlaces)
      } catch (error) {
        console.error('Error fetching filter data:', error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchMalls = async () => {
      if (selectedCity) {
        try {
          const malls = await fetchMallsByCity(
            selectedCity.value,
            selectedPOI.map((item) => item.value)
          )
          setCityMalls(malls)
        } catch (error) {
          console.error('Error fetching malls by city:', error)
        }
      }
    }

    fetchMalls()
  }, [selectedCity])

  const handleFilterChange = (selectedCity, selectedPOI, dateRange, fetchedPlaces) => {
    if (selectedPOI.length > 0 && selectedCity && dateRange.length >= 1) {
      const performAction = () => {
        const selectedPlaceNames =
          fetchedPlaces.length > 0
            ? fetchedPlaces.map((place) => place.value)
            : places.map((place) => place.value)
        const formattedDates = dateRange.map((date) => {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        })
        const year = dateRange[0].split('-')[0]
        setFilterData({
          poi_category: selectedPOI.map((poi) => poi.value).join(','),
          city: selectedCity.value,
          place_name: selectedPlaceNames.filter((item) => item !== 'all'),
          date: formattedDates,
          start_date: startDate?.toISOString().split('T')[0],
          end_date: endDate?.toISOString().split('T')[0],
          year: year,
        })
      }
      performAction()
    }
  }

  const handlePOIChange = (selectedOptions: MultiValue<OptionType>) => {
    setSelectedPOI(selectedOptions)
    setSelectedCity(null)
    setSelectedPlace([])
    setCityMalls([])
  }

  const handleCityChange = (selectedOption: SingleValue<OptionType>) => {
    if (
      selectedOption?.value &&
      ['Pune', 'Chennai', 'Bangalore', 'New_Delhi'].includes(selectedOption?.value)
    )
      return
    setSelectedCity(selectedOption)
    setSelectedPlace([])
    setCityMalls([])
  }

  const getDatesInRange = (startDate: string, endDate: string): string[] => {
    const start = new Date(startDate)
    const end = new Date(endDate)
    start.setDate(start.getDate() + 1)
    end.setDate(end.getDate() + 1)
    const dates: string[] = []
    let currentDate = new Date(start)

    while (currentDate <= end) {
      dates.push(currentDate.toISOString().split('T')[0])
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return dates
  }

  const handleDateChange = (date: Date | null, isStart: boolean) => {
    if (date) {
      if (isStart) {
        setStartDate(date)
        if (startDate && endDate) {
          const dates = getDatesInRange(
            date.toISOString().split('T')[0],
            endDate.toISOString().split('T')[0]
          )
          setDateRange(dates)
        }
      } else {
        setEndDate(date)
        if (startDate && endDate) {
          const dates = getDatesInRange(
            startDate.toISOString().split('T')[0],
            date.toISOString().split('T')[0]
          )
          setDateRange(dates)
        }
      }
    }
  }

  const clearAllFilters = () => {
    setSelectedPOI([])
    setSelectedCity(null)
    setSelectedPlace([])
    setCityMalls([])
  }

  return (
    <div className='filters' style={{padding: '1rem 1rem 0.1rem 1rem'}}>
      <div className='my-2'>
        <div className='row mb-4'>
          <div className='col-lg-2'>
            <POICategorySelector
              poiCategories={poiCategories}
              selectedPOI={selectedPOI}
              handlePOIChange={handlePOIChange}
            />
          </div>
          <div className='col-lg-2'>
            <CitySelector
              cities={cities}
              selectedCity={selectedCity}
              handleCityChange={handleCityChange}
              selectedPOI={selectedPOI}
            />
          </div>
          <div className='col-lg-2'>
            <PlaceSelector
              selectedCity={selectedCity}
              cityMalls={cityMalls}
              places={places}
              selectedPlace={selectedPlace}
              selectedPOI={selectedPOI}
              setSelectedPlace={setSelectedPlace}
            />
          </div>
          <div className='col-lg-3'>
            <DatePickerWrapper
              handleDateChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
          <div className='col-lg-3 mt-4'>
            <FilterButtons
              handleFilterChange={() =>
                handleFilterChange(selectedCity, selectedPOI, dateRange, selectedPlace)
              }
              clearFilters={clearAllFilters}
            />
          </div>
        </div>

        <div className='row mb-6'></div>
      </div>
    </div>
  )
}

interface filterButtonInterfave {
  handleFilterChange: any
  clearFilters: any
}
const FilterButtons = ({handleFilterChange, clearFilters}: filterButtonInterfave) => {
  return (
    <div className='filter-buttons'>
      <button
        className='btn btn-secondary px-2 py-1 fs-6 clear-button font-sans'
        style={{}}
        onClick={clearFilters}
      >
        Clear All Filters
      </button>
      <button
        className='btn btn-primary px-2 py-1 fs-6 filter-button font-sans'
        style={{}}
        onClick={handleFilterChange}
      >
        Apply Filters
      </button>
    </div>
  )
}

interface CitySelectorProps {
  cities: Array<{label: string; value: string}>
  selectedCity: SingleValue<{label: string; value: string}>
  handleCityChange: (selectedOption: any) => void
  selectedPOI: MultiValue<OptionType>
}

const CitySelector: FC<CitySelectorProps> = ({
  cities,
  selectedCity,
  handleCityChange,
  selectedPOI,
}) => {
  const customStyles: StylesConfig = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 150,
      overflowY: 'auto',
    }),
  }

  return (
    <div className=''>
      <div className=' mb-2'>
        <label className='fw-bold fs-6'>
          <span>City</span>
        </label>
      </div>
      <div className=' '>
        <Select
          className='basic-single-select fs-6'
          classNamePrefix='select'
          name='city'
          options={[
            ...cities,
            {label: 'Pune', value: 'Pune'},
            {label: 'New Delhi', value: 'New_Delhi'},
            {label: 'Bangalore', value: 'Bangalore'},
            {label: 'Chennai', value: 'Chennai'},
          ]}
          value={selectedCity}
          onChange={handleCityChange}
          menuPlacement='bottom'
          isDisabled={selectedPOI.length === 0}
          styles={customStyles}
        />
      </div>
    </div>
  )
}

interface POICategorySelectorProps {
  poiCategories: Array<{label: string; value: string}>
  selectedPOI: MultiValue<{label: string; value: string}>
  handlePOIChange: (selectedOptions: any) => void
}

const POICategorySelector: FC<POICategorySelectorProps> = ({
  poiCategories,
  selectedPOI,
  handlePOIChange,
}) => {
  const customStyles: StylesConfig = {
    control: (provided) => ({
      ...provided,
      height: 40,
      overflowY: 'auto',
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: 40,
      zIndex: 9999,
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: 40,
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 40,
      overflowY: 'auto',
    }),
  }

  return (
    <div className='mr-2'>
      <div className='  mb-2'>
        <label className='fw-bold fs-6 font-sans'>
          <span>POI Category</span>
        </label>
      </div>
      <div className=' '>
        <Select
          placeholder='POI Categories'
          isMulti
          name='poi_category'
          options={poiCategories}
          value={selectedPOI}
          onChange={handlePOIChange}
          className='basic-multi-select fs-6 text-gray font-sans'
          classNamePrefix='select'
          menuPlacement='bottom'
          styles={customStyles}
        />
      </div>
    </div>
  )
}

interface PlaceSelectorProps {
  selectedCity: OptionType | null
  cityMalls: Array<{label: string; value: string}>
  places: Array<{label: string; value: string}>
  selectedPlace: MultiValue<{label: string; value: string}>
  selectedPOI: MultiValue<OptionType>
  setSelectedPlace: (selectedOptions: MultiValue<{label: string; value: string}>) => void
}

const PlaceSelector: FC<PlaceSelectorProps> = ({
  selectedCity,
  cityMalls,
  places,
  selectedPlace,
  selectedPOI,
  setSelectedPlace,
}) => {
  const [allSelected, setAllSelected] = useState(false)
  const customStyles: StylesConfig = {
    control: (provided) => ({
      ...provided,
      height: 40,
      overflowY: 'auto',
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: 40,
      overflowY: 'auto',
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: 150,
      zIndex: 9999,
      overflowY: 'auto',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 150,
      overflowY: 'auto',
    }),
  }

  const handleSelect = (selectedOptions) => {
    if (
      selectedOptions &&
      Array.isArray(selectedOptions) &&
      selectedOptions.find((item) => item?.value === 'all')
    ) {
      if (!allSelected) {
        setSelectedPlace([
          {label: 'Select All', value: 'all'},
          ...(selectedCity ? cityMalls : places),
        ])
        setAllSelected(true)
      } else {
        setSelectedPlace(selectedOptions.filter((item) => item?.value !== 'all'))
        setAllSelected(false)
      }
      return
    }
    setAllSelected(false)
    return setSelectedPlace(selectedOptions)
  }
  return (
    <div className='' style={{height: '100%'}}>
      <div className='mb-2'>
        <label className='fw-bold fs-6 font-sans'>
          <span>Place Name</span>
        </label>
      </div>
      <div className=''>
        <Select
          placeholder='Places'
          isMulti
          name='place_name'
          options={[{label: 'Select All', value: 'all'}, ...(selectedCity ? cityMalls : places)]}
          value={selectedPlace}
          onChange={(selectedOptions: any) => handleSelect(selectedOptions)}
          className='basic-multi-select fs-6 font-sans'
          classNamePrefix='select'
          menuPlacement='bottom'
          isDisabled={selectedPOI.length === 0 || !selectedCity}
          styles={customStyles}
        />
      </div>
    </div>
  )
}

interface datePicker {
  handleDateChange: any
  startDate: Date | null
  endDate: Date | null
  setStartDate?: (startDate: Date | null) => void
  setEndDate?: (endDate: Date | null) => void
}

const DatePickerWrapper = ({
  handleDateChange,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: datePicker) => {
  const handleClean = (isStart: boolean) => (event: React.MouseEvent) => {
    if (isStart) {
      setStartDate?.(null)
      if (endDate) {
        handleDateChange(null, false)
      } else {
        handleDateChange(null, false)
      }
    } else {
      setEndDate?.(null)
      if (startDate) {
        handleDateChange(null, true)
      } else {
        handleDateChange(null, true)
      }
    }
  }

  return (
    <div className=' ' style={{width: '100%', height: '100%', zIndex: '55'}}>
      <div className=' mb-2'>
        <label className='fw-bold fs-6 fs-6 font-sans'>
          <span className=''>Date</span>
        </label>
      </div>
      <div className=' '>
        <div style={{display: 'flex', gap: '15px', height: '40px'}}>
          <InputGroup style={{width: '100%'}}>
            <DatePicker
              format='yyyy-MM-dd'
              placeholder=''
              style={{
                width: '50%',
                zIndex: '155',
                fontSize: '16px',
                fontFamily: 'sans-serif',
                color: 'gray',
              }}
              onChange={(date) => handleDateChange(date, true)}
              value={startDate}
              onClean={handleClean(true)}
              menuStyle={{zIndex: 10000}}
            />
            <InputGroup.Addon>to</InputGroup.Addon>
            <DatePicker
              format='yyyy-MM-dd'
              placeholder=''
              style={{
                width: '50%',
                zIndex: '155',
                fontSize: '16px',
                fontFamily: 'sans-serif',
                color: 'gray',
              }}
              onChange={(date) => handleDateChange(date, false)}
              value={endDate}
              menuStyle={{zIndex: 10000}}
              onClean={handleClean(false)}
            />
          </InputGroup>
        </div>
      </div>
    </div>
  )
}

export default Filters
