import React, {useEffect, useState, useCallback} from 'react'
import ReactECharts from 'echarts-for-react'
import * as echarts from 'echarts'
import {fetchAggWeeklyTrend, fetchCompWeeklyTrend} from '../../service/apiService'
import {FiltersState} from './Interfaces'
import {ClipLoader} from 'react-spinners'

interface DailyVisitorLineGraphProps {
  filters: {
    poi_category: string
    city: string
    place_name: string[]
    date: string[]
  }
  data: any
}

const MIN_LOADING_TIME = 1000 // 1 second
const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const Daily_Visitor_Line_Graph: React.FC<DailyVisitorLineGraphProps> = ({filters, data}) => {
  const [graphType, setGraphType] = useState<'agg' | 'comp'>('agg')
  const [aggData, setAggData] = useState<number[]>([])
  const [compData, setCompData] = useState<{name: string; data: string[]; count: number[]}[]>([])
  const [categories, setCategories] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [parentWidth, setParentWidth] = useState(0)

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        await delay(100)
        if (graphType === 'agg') {
          const response = data?.aggData
          setCategories(response.data)
          setAggData(response.visitor)
        } else {
          const response = data?.compData
          setCompData(response)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error fetching data:', error)
      } finally {
      }
    }

    if (filters.place_name.length > 0) {
      fetchData()
    }
  }, [filters, graphType, data])

  const aggOptions: echarts.EChartsOption = {
    backgroundColor: '#ffffff',
    legend: {
      data: ['Aggregate Data'], 
      textStyle: {
        fontSize: 12,
        fontFamily:'sans-serif' 
      },
      top: '5%', 
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '8%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: categories,
      axisLabel: {
        interval: 1,
        formatter: (value: string) => {
          const hour = parseInt(value.split(':')[0])
          return hour % 2 === 0 ? value : ''
        },
      },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Aggregate Data', 
        data: aggData,
        type: 'line',
        smooth: true,
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        fontSize: 13,
        fontWeight: 700
      },
      formatter: function (params) {
        params = params.sort((a, b) => b.value - a.value) // Sort tooltip items by value descending
        let tooltipText = `<strong>${params[0].axisValue}</strong><br/>` // Display the axis value as the header
  
        params.forEach((param) => {
          tooltipText += `<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${param.color}"></span>${param.value}`
        })
  
        return tooltipText
      },
    },
  }
  

  const compOptions: echarts.EChartsOption = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      type: 'scroll',
      orient: 'horizontal',
      formatter: (name: string) => name.split('_').join(' '),
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '8%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: weekDays,
    },
    yAxis: {
      type: 'value',
    },
    series:
      compData &&
      Object.keys(compData).map((name) => ({
        name: name.replace(/_/g, ' '),
        type: 'line', // Change type to 'line' for a normal line chart
        smooth: true,
        data: compData
          ? compData[name]?.map((value, index) => ({
              hour: weekDays[index],
              value: value,
            }))
          : [],
      })),
    // compData && Array.isArray(compData)
    //   ? compData?.map((item) => ({
    //       name: item.name?.replace(/_/g, ' '),
    //       type: 'line',
    //       stack: 'Total',
    //       smooth: true,
    //       data: item.count,
    //     }))
    //   : [],
  }

  const handleGraphTypeChange = useCallback((type: 'agg' | 'comp') => {
    setGraphType(type)
  }, [])

  // Function to update width state on resize
  const updateParentWidth = () => {
    const element = document.getElementById('daily-visitor-line-graph-wrapper')
    if (element) {
      const width = element.clientWidth
      setParentWidth(width)
    }
  }

  // Effect to add resize listener and initial width setting
  useEffect(() => {
    updateParentWidth() // Set initial width
    window.addEventListener('resize', updateParentWidth) // Add resize listener
    return () => {
      window.removeEventListener('resize', updateParentWidth) // Cleanup on component unmount
    }
  }, [])
  return (
    <div
      className='shadow-xs bg-white rounded p-2'
      style={{boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', height: '372.350px'}}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
          marginTop: '5px',
        }}
      >
        <p className='my-1 mx-3' style={{fontWeight: '700', fontSize: '1.5rem', fontFamily:'sans-serif', color: 'black'}}>
          Average Weekly Trend
        </p>
        <div>
          <button
            className={`btn me-3 px-4 py-1 fs-7 font-sans ${
              graphType === 'agg' ? 'btn-primary' : 'btn-light-primary'
            }`}
            onClick={() => handleGraphTypeChange('agg')}
            style={{marginRight: '5px'}}
          >
            Agg
          </button>
          <button
            className={`btn me-3 px-4 py-1 fs-7 font-sans ${
              graphType === 'comp' ? 'btn-primary' : 'btn-light-primary'
            }`}
            onClick={() => handleGraphTypeChange('comp')}
          >
            Comp
          </button>
        </div>
      </div>
      <div
        id='daily-visitor-line-graph-wrapper'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '300px',
        }}
      >
        {loading ? (
          <ClipLoader color='#1e90ff' />
        ) : (
          <>
            {graphType === 'agg' &&
              (aggData && Array.isArray(aggData) ? (
                <ReactECharts
                  option={aggOptions}
                  style={{height: '300px', width: `${parentWidth}px`}}
                />
              ) : (
                'Not data to show'
              ))}
            {graphType === 'comp' && (
              <ReactECharts
                option={compOptions}
                style={{height: '300px', width: `${parentWidth}px`}}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Daily_Visitor_Line_Graph
