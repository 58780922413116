import React from 'react';
import { useIntl } from 'react-intl';
import { KTIcon } from '../../../../helpers';
import { SidebarMenuItem } from './SidebarMenuItem';
import { useAuth } from '../../../../../app/modules/auth';

const SidebarMenuMain = () => {
  const intl = useIntl();
  const {currentUser} = useAuth()

  return (
    <>
      {/* <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      /> */}
    
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11' 
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />
      
     </>
        )
      }
      
export { SidebarMenuMain };