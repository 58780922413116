import React, {useEffect, useState} from 'react'
import ReactEcharts from 'echarts-for-react'
import * as echarts from 'echarts'
import {fetchAggData, fetchCompData} from '../../service/apiService'
import {ClipLoader} from 'react-spinners'

interface LineChart24HoursProps {
  filters: {
    poi_category: string
    city: string
    place_name: string[]
    date: string[]
  }
  data: any
}

const MIN_LOADING_TIME = 1000 // 1 second

const LineChart24Hours: React.FC<LineChart24HoursProps> = ({filters, data}) => {
  const hours = Array.from({length: 24}, (_, i) => `${i}:00`)
  const [graphType, setGraphType] = useState<'agg' | 'comp'>('agg')
  const [aggData, setAggData] = useState<number[]>(Array(24).fill(0))
  const [compData, setCompData] = useState<{[key: string]: number[]}>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [parentWidth, setParentWidth] = useState(0)

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const fetchData = async () => {
    const requestData = {
      poi_category: filters.poi_category,
      city: filters.city,
      place_name: filters.place_name,
      date: filters.date,
    }
    try {
      setLoading(true)
      await delay(100)
      if (graphType === 'agg') {
        const response = data?.aggData //|| (await fetchAggData(requestData))
        const hourlyData = response.map((count: any) => parseInt(count, 10))
        setAggData(hourlyData)
      } else {
        const response = data?.compData //await fetchCompData(requestData)
        const mallData: {[key: string]: number[]} = {}
        response.forEach((item: any) => {
          const name = item.name.replace(/_/g, ' ')
          mallData[name] = item.count.map((count: any) => parseInt(count, 10))
        })
        setCompData(mallData)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [graphType, data?.compData, data?.aggData])

  const aggOptions: echarts.EChartsOption = {
    backgroundColor: '#ffffff',
    grid: {
      left: '8%',
      right: '4%',
      bottom: '15%',
    },
    xAxis: {
      type: 'category',
      data: hours,
      boundaryGap: false,
      axisLabel: {
        interval: 1,
        formatter: (value: string) => {
          const hour = parseInt(value.split(':')[0])
          return hour % 2 === 0 ? value : ''
        },
      },
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Aggregate Data',
        data: aggData,
        type: 'line',
        smooth: true,
      },
    ],
    legend: {
      data: ['Aggregate Data'],
      textStyle: {
        fontSize: 12,
      },
      top: '5%',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        fontSize: 13,
        fontWeight: 700
      },
      formatter: function (params) {
        params = params.sort((a, b) => b.value - a.value) 
        let tooltipText = `<strong>${params[0].axisValue}</strong><br/>` 
        params.forEach((param) => {
          tooltipText += `<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${param.color}"></span>${param.value}`
        })

        return tooltipText
      },
    },
  }

  const compOptions = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: Object.keys(compData).map((name) => name.replace(/_/g, ' ')),
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '8%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: hours,
      boundaryGap: false,
      axisLabel: {
        interval: 1,
        formatter: (value) => {
          const hour = parseInt(value.split(':')[0])
          return hour % 2 === 0 ? value : ''
        },
      },
    },
    yAxis: {
      type: 'value',
    },
    series: Object.keys(compData).map((name) => ({
      name: name.replace(/_/g, ' '),
      type: 'line', 
      smooth: true,
      data: compData[name].map((value, index) => ({
        hour: hours[index],
        value: value,
      })),
    })),
  }

  const handleGraphTypeChange = (type: 'agg' | 'comp') => {
    setGraphType(type)
  }

  // Function to update width state on resize
  const updateParentWidth = () => {
    const element = document.getElementById('lineChart24Hours-wrapper')
    if (element) {
      const width = element.clientWidth
      setParentWidth(width)
    }
  }

  // Effect to add resize listener and initial width setting
  useEffect(() => {
    updateParentWidth() // Set initial width
    window.addEventListener('resize', updateParentWidth) // Add resize listener
    return () => {
      window.removeEventListener('resize', updateParentWidth) // Cleanup on component unmount
    }
  }, [])

  return (
    <div
      className='shadow-xs bg-white rounded p-2'
      style={{boxShadow: ' 0 0 10px rgba(0, 0, 0, 0.2)'}}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
          marginTop: '5px',
        }}
      >
        <p className='my-1 mx-3' style={{fontWeight: '700', fontSize: '1.5rem', fontFamily:'sans-serif', color: 'black'}}>
          Average Hourly Trend
        </p>
        <div>
          <button
            className={`btn me-3 px-4 py-1 fs-7 font-sans ${
              graphType === 'agg' ? 'btn-primary' : 'btn-light-primary'
            }`}
            onClick={() => handleGraphTypeChange('agg')}
            style={{marginRight: '5px'}}
          >
            Agg
          </button>
          <button
            className={`btn me-3 px-4 py-1 fs-7 font-sans ${
              graphType === 'comp' ? 'btn-primary' : 'btn-light-primary'
            }`}
            onClick={() => handleGraphTypeChange('comp')}
          >
            Comp
          </button>
        </div>
      </div>

      <div
        id='lineChart24Hours-wrapper'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '300px',
        }}
      >
        {loading ? (
          <ClipLoader color='#1e90ff' />
        ) : (
          <>
            {graphType === 'agg' && (
              <ReactEcharts
                option={aggOptions}
                style={{height: '300px', width: `${parentWidth}px`}}
              />
            )}
            {graphType === 'comp' && (
              <ReactEcharts
                option={compOptions}
                style={{height: '300px', marginTop: '0', width: `${parentWidth}px`}}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default LineChart24Hours
