import React, {useEffect, useState, useRef} from 'react'
import ReactECharts from 'echarts-for-react'
import {fetchDailyVisitorCount, DailyVisitorCountResponse} from '../../service/apiService'
import {DataPoint} from './types'
import {ClipLoader} from 'react-spinners'
import * as ss from 'simple-statistics'
import chroma from 'chroma-js'

const colorRange = ['#fae7a2', '#f6cd78', '#f3b557', '#eb7624', '#c5642f', '#a04b27']

interface DailyHeatmapProps {
  filters: {
    poi_category: string
    city: string
    place_name: string[]
    date: string[]
  }
}

const MIN_LOADING_TIME = 1000 // 1 second

const DailyHeatmap: React.FC<DailyHeatmapProps> = ({filters}) => {
  const [dailyVisitorData, setDailyVisitorData] = useState<DataPoint[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedYear, setSelectedYear] = useState<string>('2024')
  const [parentWidth, setParentWidth] = useState<number>(0)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const fetchData = async () => {
    const startTime = Date.now()
    setLoading(true)

    const requestData = {
      poi_category: filters.poi_category,
      city: filters.city,
      place_name: filters.place_name,
      year: selectedYear,
      date: filters.date,
    }

    try {
      const response: DailyVisitorCountResponse = await fetchDailyVisitorCount(requestData)
      const formattedData = response.DailyVisitorCount.map((item) => ({
        date: item.date.split('-').reverse().join('-'),
        visitors: parseInt(item.visitorcout),
      }))
      setDailyVisitorData(formattedData)
    } catch (error) {
      console.error('Error fetching daily visitor count:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [filters.place_name, selectedYear])

  useEffect(() => {
    if (filters?.date && filters?.date.length - 1 >= 0) {
      const dateList = filters?.date[filters?.date.length - 1]?.split('-')
      if (dateList.length - 1) {
        const year = dateList[dateList.length - 1]
        setSelectedYear(year)
      }
      // console.log(filters)
    }
  }, [filters.date])
  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(e.target.value)
  }

  // Handle empty data
  const visitorCounts =
    dailyVisitorData.length > 0 ? dailyVisitorData.map((item) => item.visitors) : [0]
  const maxVisitorVale = visitorCounts.length > 0 ? Math.max(...visitorCounts) : 10000

  // Calculate Jenks breaks
  const numberOfBreaks = 6 // Number of color categories

  // Calculate Jenks breaks
  let jenksBreaks
  if (visitorCounts.length > 1) {
    try {
      jenksBreaks = ss.jenks(visitorCounts, numberOfBreaks)
    } catch (error) {
      console.error('Error calculating Jenks breaks:', error)
      jenksBreaks = null
    }
  } else {
    // Provide default breaks if not enough data
    jenksBreaks = [0, 2000, 4000, 6000, 8000, 10000]
    console.log('Default Jenks breaks used:', jenksBreaks)
  }

  // Ensure jenksBreaks is always an array
  if (!jenksBreaks || jenksBreaks.length === 0) {
    jenksBreaks = [0, 2000, 4000, 6000, 8000, 10000]
    console.log('Jenks breaks defaulted to:', jenksBreaks)
  }

  // Assign each data point a color based on Jenks breaks
  const coloredData = dailyVisitorData.map((item) => {
    const colorIndex = jenksBreaks.findIndex((breakpoint, index) => {
      return item.visitors <= breakpoint && item.visitors > (jenksBreaks[index - 1] || -Infinity)
    })
    return {
      ...item,
      color: colorRange[colorIndex],
    }
  })

  const option = {
    tooltip: {
      position: 'top',
    },
    visualMap: {
      min: 0,
      max: !isNaN(maxVisitorVale) && isFinite(maxVisitorVale) ? maxVisitorVale : 10000,
      calculable: true,
      orient: 'horizontal',
      left: 'center',
      bottom: 10,
      inRange: {
        color: colorRange,
      },
    },
    calendar: {
      top: 'middle',
      left: 'center',
      width: parentWidth - 150, // Set the calendar width to the parent width
      cellSize: [20, 20],
      yearLabel: {
        show: true,
        textStyle: {
          fontSize: 30,
        },
      },
      dayLabel: {
        firstDay: 1,
        nameMap: 'en',
      },
      monthLabel: {
        nameMap: 'en',
      },
      range: selectedYear,
    },
    series: [
      {
        type: 'heatmap',
        coordinateSystem: 'calendar',
        data: coloredData.map((item) => [item.date, item.visitors]),
      },
    ],
  }

  const updateParentWidth = () => {
    if (wrapperRef.current) {
      const width = wrapperRef.current.clientWidth
      const calendarWidth = window.innerWidth * 0.8
      setParentWidth(width - 150 < calendarWidth ? width : calendarWidth + 150)
    }
  }

  useEffect(() => {
    updateParentWidth()
    window.addEventListener('resize', updateParentWidth)
    return () => {
      window.removeEventListener('resize', updateParentWidth)
    }
  }, [])

  return (
    <div ref={wrapperRef} style={{width: '100%'}} id='daily-heat-map-wrapper'>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '300px',
          }}
        >
          <ClipLoader color='#1e90ff' />
        </div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              padding: '0 20px',
              marginTop: '5px',
            }}
          >
            <div>
              <label htmlFor='yearSelect' className="font-sans fs-5" style={{marginRight: '10px'}}>
                Select Year:{' '}
              </label>
              <select
                id='yearSelect'
                value={selectedYear}
                onChange={handleYearChange}
                style={{
                  borderColor: 'gray',
                  borderRadius: '5px',
                  padding: '5px',
                  width: '100px',
                }}
                className='font-sans fs-6'
              >
                <option value='2021' className='font-sans fs-6'>2021</option>
                <option value='2022' className='font-sans fs-6'>2022</option>
                <option value='2023' className='font-sans fs-6'>2023</option>
                <option value='2024' className='font-sans fs-6'>2024</option>
              </select>
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <ReactECharts option={option} style={{height: '300px', width: `${parentWidth}px`}} />
          </div>
        </>
      )}
    </div>
  )
}

export default DailyHeatmap
