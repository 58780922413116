import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../core/Auth'
import axios from 'axios'

const initialValues = {
  email: 'admin@demo.com',
  password: 'admin',
}

interface LoginCred {
  email: string
  password: string
}
const API_HOST = process.env.REACT_APP_API_URL
const API_URL_LOGIN = API_HOST + '/login'

const loginAPICall = async (email, password) => {
  // Fetch card data for widgets based on filters
  const fetchWidgetsData = async (data: any): Promise<any> => {
    return await axios
      .post<any>(API_URL_LOGIN, data)
      .then((response) => {
        // Handle successful response
        console.log('Response:', response.data)
        return response.data
      })
      .catch((error) => {
        // Handle error, including 500 Internal Server Error
        if (error.response) {
          // The request was made and the server responded with a status code
          console.error('Data:', error.response.data)
          alert(error.response.data)
        } else if (error.request) {
          // The request was made but no response was received
          console.log('No response received:', error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error:', error.message)
        }
      })
  }
  return await fetchWidgetsData({user_name: email, password})
}

const Login: React.FC = () => {
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()
  const [loginCred, setLoginCred] = useState<LoginCred>({email: '', password: ''})

  const loginHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      const loginResponse = await loginAPICall(loginCred.email, loginCred.password)
      if (loginResponse?.success) {
        const data = {
          api_token: loginResponse?.token,
          refreshToken: 'string',
        }
        saveAuth(data)
        const user = {
          username: loginResponse?.username,
        }
        setCurrentUser(user)
        navigate('/dashboard')
      }
    } catch (error: any) {
      console.error('Error while logging in: ', error?.message)
    }
  }

  return (
    <div
      className='d-flex flex-column flex-lg-row flex-column-fluid'
      style={{
        backgroundImage:
          'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4)), url(assets/media/auth/mapbg.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className='d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10'>
        <div className='d-flex justify-content-between flex-column-fluid flex-column w-100 mw-500px'>
          <div className='py-20'>
            <form className='form w-100' onSubmit={loginHandler}>
              <div
                className='card card-flush'
                style={{
                  height: '370px',
                  padding: '30px 20px',
                  width: '450px',
                  backgroundColor: 'transparent',
                  border: 'none',
                }}
              >
                <div className='card-body d-flex flex-column justify-content-start align-items-center'>
                  <div className='text-center mb-4' style={{width: '100%'}}>
                    <h1 className='text-white mb-5 fs-2x'>Sign In</h1>
                    <p className='text-white-50'>Enter your email and password to sign in</p>
                  </div>
                  <div className='fv-row mb-7 w-100'>
                    <input
                      type='text'
                      placeholder='Email'
                      name='email'
                      autoComplete='off'
                      value={loginCred.email}
                      onChange={(e) => setLoginCred({...loginCred, email: e.target.value})}
                      className='form-control form-control-lg form-control-solid'
                      style={{
                        height: '40px',
                        fontSize: '14px',
                        backgroundColor: 'transparent',
                        borderColor: '#a9a9a9',
                        color: '#fff',
                      }}
                    />
                  </div>
                  <div className='fv-row mb-7 w-100'>
                    <input
                      type='password'
                      placeholder='Password'
                      name='password'
                      autoComplete='off'
                      value={loginCred.password}
                      onChange={(e) => setLoginCred({...loginCred, password: e.target.value})}
                      className='form-control form-control-lg form-control-solid'
                      style={{
                        height: '40px',
                        fontSize: '14px',
                        backgroundColor: 'transparent',
                        borderColor: '#a9a9a9',
                        color: '#fff',
                      }}
                    />
                  </div>

                  <div className='d-flex flex-stack mb-5 w-100 justify-content-center align-items-center'>
                    <button
                      type='submit'
                      className='btn'
                      style={{
                        width: '350px',
                        height: '40px',
                        backgroundColor: '#1ca9c9',
                        fontSize: '15px',
                        color: 'white',
                      }}
                    >
                      <span className='indicator-label'>Sign In</span>
                      <span className='indicator-progress'>
                        <span>Please wait...</span>
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className='d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat'
        style={{backgroundImage: 'url(assets/media/auth/bg11.png)'}}
      ></div>
    </div>
  )
}

export default Login
