import React, {useState, useCallback} from 'react'
import DailyHeatmap from './DailyHeatmap'
import WeeklyLineGraph from './WeeklyLineGraph'
import MonthlyLineGraph from './MonthlyLineGraph'

interface BarChartProps {
  filters: {
    poi_category: string
    city: string
    place_name: string[]
    date: string[]
    year: string
  }
}

const BarChart: React.FC<BarChartProps> = ({filters}) => {
  const [viewType, setViewType] = useState<'Daily' | 'Weekly' | 'Monthly'>('Daily')

  const renderChart = useCallback(() => {
    switch (viewType) {
      case 'Daily':
        return <DailyHeatmap filters={filters} />
      case 'Weekly':
        return <WeeklyLineGraph filters={filters} />
      case 'Monthly':
        return <MonthlyLineGraph filters={filters} />
      default:
        return <DailyHeatmap filters={filters} />
    }
  }, [viewType, filters])

  const getViewTitle = useCallback(() => {
    switch (viewType) {
      case 'Daily':
        return 'Daily Visitor Count'
      case 'Weekly':
        return 'Weekly Visitor Count'
      case 'Monthly':
        return 'Monthly Visitor Count'
      default:
        return 'Visitor Count'
    }
  }, [viewType])

  return (
    <div className='bg-white rounded shadow-xs' style={{boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <p className='my-1 mx-3' style={{fontWeight: '700', fontSize: '1.5rem', color: 'black'}}>
          {getViewTitle()}
        </p>
        <div className='ms-5 pt-2'>
          <button
            className={`btn me-3 px-2 py-1 fs-7 ${
              viewType === 'Daily' ? 'btn-primary' : 'btn-light-primary'
            }`}
            onClick={() => setViewType('Daily')}
          >
            D
          </button>
          {/* <button
            className={`btn me-3 px-2 py-1 fs-7 ${
              viewType === 'Weekly' ? 'btn-primary' : 'btn-light-primary'
            }`}
            onClick={() => setViewType('Weekly')}
          >
            W
          </button> */}
          <button
            className={`btn me-3 px-2 py-1 fs-7 ${
              viewType === 'Monthly' ? 'btn-primary' : 'btn-light-primary'
            }`}
            onClick={() => setViewType('Monthly')}
          >
            M
          </button>
        </div>
      </div>
      {renderChart()}
    </div>
  )
}

export default BarChart
