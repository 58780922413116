import React, {useCallback, useEffect, useRef, useState} from 'react'
import mapboxgl from 'mapbox-gl'
import {KTIcon} from '../../../../_metronic/helpers'
import {fetchMallDetails, MallDetailsResponse} from '../../service/apiService'
import {convertAllFeatureProperties, convertNameString, isNumber} from '../../utils'
import {ckmeans} from 'simple-statistics'
import * as ss from 'simple-statistics'
import chroma from 'chroma-js'

// const colorRange = ['#e6ff03', '#e7f754', '#038c03']
// blue
// const colorRange = ['#fffdd7', '#94a793', '#7acdd1', '#096e9c']
//orange
// const colorRange = ['#fc9272', '#fb6a4a', '#de2d26', '#a50f15']
// brown
const choroplethColorRange = ['#fae7a2', '#f6cd78', '#f3b557', '#eb7624', '#c5642f', '#a04b27']
const pointColorRange = ['#FF0000', 'orange', 'yellow', 'limegreen', '#00FF00']
const CHOROPLETH_LAYER = 'choropleth-layer'
const CHOROPLETH_TEXT_LAYER = 'choropleth-text-layer'
const CHOROPLETH_POINT_LAYER = 'choropleth-point-layer'
const BODER_LAYER = 'border-layer'
const POINT_LAYER = 'points-layer'
const LABLE_LAYER = 'lable-layer'
interface HeatmapProps {
  filters: {
    poi_category: string
    city: string
    place_name: string[]
  }
  locationMap: {}
  setLocationMap: any
  map: mapboxgl.Map | null
  setMap: any
  setDefualtBounds: any
  data: any
  choroplethData: any
  choroplethPointData: any
  showLocalityChoropleth: any
  setShowLocalityChoropleth: any
}

const accessToken = process.env.REACT_APP_MAPBOX_KEY

const calculateBoundingBox = (geojsonData) => {
  if (!geojsonData || !geojsonData.features || !Array.isArray(geojsonData.features)) {
    console.error('Invalid GeoJSON data')
  }

  let minLon = Infinity,
    maxLon = -Infinity,
    minLat = Infinity,
    maxLat = -Infinity

  geojsonData?.features?.forEach((feature) => {
    if (
      feature?.geometry &&
      feature.geometry.coordinates &&
      Array.isArray(feature.geometry.coordinates)
    ) {
      const [lon, lat] = feature.geometry.coordinates

      if (typeof lon === 'number' && typeof lat === 'number') {
        if (lon < minLon) minLon = lon
        if (lon > maxLon) maxLon = lon
        if (lat < minLat) minLat = lat
        if (lat > maxLat) maxLat = lat
      }
    }
  })

  if (minLon === Infinity || maxLon === -Infinity || minLat === Infinity || maxLat === -Infinity) {
    console.error('No valid coordinates found in GeoJSON data')
  }

  return [minLon, minLat, maxLon, maxLat]
}

// const getMapTopLeftButon = () => {
//   const buttons = document.createElement('div')
//   buttons.style.position = 'absolute'
//   buttons.style.top = '10px'
//   buttons.style.left = '10px'
//   buttons.style.zIndex = '10'
//   buttons.innerHTML = `
//   <button style="margin-right: 10px; border-radius: 5px; border: 1px solid grey;">Locality</button>
//   <button style="margin-right: 10px; border-radius: 5px; border: 1px solid grey;">Pincode</button>
//   <button style="border-radius: 5px; border: 1px solid grey;">Geohash</button>
// `
//   return buttons
// }

const calculateLayerColors = (dataClone, numClasses, colorRange, property) => {
  const visitorsData =
    dataClone?.features.map((feature) =>
      feature?.properties?.[property] ? Number(feature.properties[property]) : 0
    ) || []

  // Calculate Jenks breaks
  const breaks = ss.jenks(visitorsData, numClasses)
  // Ensure unique breaks and ascending order
  const uniqueBreaks = Array.from(new Set(breaks)).sort((a, b) => a - b)

  // Generate colors
  const colorScale = chroma.scale(colorRange).domain([0, numClasses - 1])
  const colors = uniqueBreaks.map((breakPoint, index) => ({
    break: breakPoint,
    color: colorScale(index).hex(),
  }))

  // Flatten color data for Mapbox interpolate expression
  const layerColors = colors.flatMap(({break: breakPoint, color}) => [breakPoint, color])

  return layerColors
}

const updateChoroplethLayerPaint = (map, layerId, colorRange, data) => {
  // Get the source data
  const source = map.getSource(CHOROPLETH_LAYER + '-source')
  if (!source) {
    console.error(`Source ${CHOROPLETH_LAYER + '-source'} not found`)
    return
  }

  // Calculate new layer colors
  const layerColors = calculateLayerColors(data, 20, colorRange, 'visitors')

  // Update the paint properties of the layer
  map.setPaintProperty(layerId, 'fill-color', [
    'interpolate',
    ['linear'],
    ['get', 'visitors'],
    ...layerColors,
  ])
}

const addChoroplethLayer = (map: any, choroplethData: any) => {
  if (!choroplethData || !choroplethData.features) return
  // Loop through the features and apply the formatting
  const dataClone = JSON.parse(JSON.stringify(choroplethData))
  if (dataClone.features)
    dataClone.features = dataClone.features.map((feature) => convertAllFeatureProperties(feature))

  map.addSource(CHOROPLETH_LAYER + '-source', {
    type: 'geojson',
    data: dataClone,
  })

  // Calculate layer colors
  const layerColors = calculateLayerColors(dataClone, 20, choroplethColorRange, 'visitors')
  map.addLayer({
    id: CHOROPLETH_LAYER,
    type: 'fill',
    source: CHOROPLETH_LAYER + '-source',
    paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'visitors'], // Adjust this to your property name for visitors
        ...layerColors,
      ],
      'fill-opacity': 0.85,
    },
  })

  // Add border layer
  map.addLayer({
    id: BODER_LAYER,
    type: 'line',
    source: CHOROPLETH_LAYER + '-source',
    paint: {
      'line-color': '#7d7c7c',
      'line-width': 1,
    },
  })
  map.addLayer({
    id: CHOROPLETH_TEXT_LAYER,
    type: 'symbol',
    source: CHOROPLETH_LAYER + '-source',
    minzoom: 11,
    layout: {
      'text-field': ['get', 'visitor_proportion'],
      'text-size': 12,
      'text-anchor': 'center',
      'text-justify': 'center',
      'text-offset': [0, 0],
    },
    paint: {
      'text-color': 'black',
    },
  })

  // Add click event listener for the choropleth layer
  map.on('click', CHOROPLETH_LAYER, (e) => {
    if (e.features.length > 0) {
      const properties = e.features[0].properties
      const coordinates = e.lngLat
      const popupContent = `
      <div  class="popup-content" >
      <h3>Location Details</h3>
      <table> 
        <tr><td><strong>Locality: </strong></td><td>${properties.locality_name}</td></tr>
        <tr><td><strong>Visitors: </strong></td><td>${properties.visitors}</td></tr>
        <tr><td><strong>Visitor Proportion: </strong></td><td>${properties.visitor_proportion}</td></tr>
        <tr><td><strong>Avg travel distance: </strong></td><td>${properties.avg_travel_distance}</td></tr>
        <tr><td><strong>Avg travel time: </strong></td><td>${properties.avg_travel_time}</td></tr>
        <tr><td><strong>Household income: </strong></td><td>${properties.household_income}</td></tr>
      </table>
    `

      new mapboxgl.Popup().setLngLat(coordinates).setHTML(popupContent).addTo(map)
    }
  })

  // Change the cursor to a pointer when over choropleth layer
  map.on('mouseenter', CHOROPLETH_LAYER, () => {
    map.getCanvas().style.cursor = 'pointer'
  })

  // Change it back to the default when not hovering
  map.on('mouseleave', CHOROPLETH_LAYER, () => {
    map.getCanvas().style.cursor = ''
  })
}
function calculateLayerColorsForPoint(
  features: any,
  numClasses: number,
  colorRange: string[],
  propertyName: any
): (number | string)[] {
  // Extract property values for the given propertyName
  const propertyValues = features.map((feature) => feature.properties[propertyName])

  // Calculate thresholds using quantiles or any other method based on numClasses
  const thresholds = calculateThresholds(propertyValues, numClasses)
  // Map thresholds to color range
  const colorStops = thresholds.map((threshold, index) => {
    return [threshold, colorRange[Math.max(0, numClasses - thresholds.length) + index]] // Assuming colorRange has enough colors
  })

  return colorStops.flat() // Flatten the array to alternate threshold and color
}

// Function to calculate thresholds
function calculateThresholds(data: number[], numClasses: number): number[] {
  // Sort data
  const sortedData = data.sort((a, b) => a - b).filter(Boolean)

  // Calculate quantiles
  const thresholds: number[] = []
  for (let i = 1; i < numClasses; i++) {
    const quantileIndex = Math.floor((sortedData.length * i) / numClasses)
    thresholds.push(sortedData[quantileIndex])
  }

  // Ensure unique thresholds
  const uniqueThresholds = Array.from(new Set(thresholds))
  return uniqueThresholds
}
const addPointLayer = (mapInstance, pointsData) => {
  if (!pointsData) return
  const dataClone = JSON.parse(JSON.stringify(pointsData))
  if (dataClone.features)
    dataClone.features = dataClone.features.map((feature) => convertAllFeatureProperties(feature))

  mapInstance.addSource(POINT_LAYER + '-source', {
    type: 'geojson',
    data: dataClone,
  })
  const numClasses = 5 // Number of color classes
  // Calculate color breaks and colors using calculateLayerColors
  const layerColors = calculateLayerColorsForPoint(
    dataClone.features,
    numClasses,
    pointColorRange,
    'visitorOriginProportion'
  )

  mapInstance.addLayer({
    id: POINT_LAYER,
    type: 'circle',
    source: POINT_LAYER + '-source',
    paint: {
      'circle-radius': 10,
      'circle-color': [
        'step',
        ['get', 'visitorOriginProportion'],
        pointColorRange[0],
        ...layerColors,
      ],
      'circle-opacity': 0.8,
    },
  })

  // Add click event listener for the points layer
  mapInstance.on('click', POINT_LAYER, (e) => {
    if (e.features.length > 0) {
      const coordinates = e.features[0].geometry.coordinates.slice()
      const properties = e.features[0].properties

      const popupContent = `
        <div class="popup-content">
          <h3>${properties.locality}</h3>
          <table>
            <tr>
              <td><strong>Visits:</strong></td>
              <td>${properties.visits}</td>
            </tr>
            <tr>
              <td><strong>Freq:</strong></td>
              <td>${properties.frequency} days</td>
            </tr>
          </table>
        </div>
      `

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }
      const newPopup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true,
      })
        .setLngLat(coordinates)
        .setHTML(popupContent)
        .addTo(mapInstance)
    }
  })
  const bounds = calculateBoundingBox(pointsData)
  return bounds
}

const addChoroplethPointLayer = (map, choroplethPointData) => {
  if (!choroplethPointData) return
  // Loop through the features and apply the formatting
  const dataClone = JSON.parse(JSON.stringify(choroplethPointData))

  console.log(dataClone)

  if (dataClone.features)
    dataClone.features = dataClone.features.map((feature) => convertAllFeatureProperties(feature))

  map.addSource(CHOROPLETH_POINT_LAYER + '-source', {
    type: 'geojson',
    data: dataClone,
  })

  map.addLayer({
    id: CHOROPLETH_POINT_LAYER,
    type: 'symbol',
    source: CHOROPLETH_POINT_LAYER + '-source',
    layout: {
      'icon-image': 'town-hall-11',
      'icon-size': 2,
      'text-field': ['get', 'place_name'],
      'text-offset': [0, 1.25],
      'text-color': 'white',
    },
  })

  // map.on('click', CHOROPLETH_POINT_LAYER, (e) => {
  // const coordinates = e.features[0].geometry.coordinates.slice()
  // const properties = e.features[0].properties
  //   const popupContent = `
  //   <div class="popup-content">
  //     <h3>${convertNameString(properties.locality)}</h3>
  //     <table>
  //       <tr>
  //         <td><strong>Visits:</strong></td>
  //         <td>${properties.visits}</td>
  //       </tr>
  //       <tr>
  //         <td><strong>Freq:</strong></td>
  //         <td>${properties.frequency} days</td>
  //       </tr>
  //     </table>
  //   </div>
  // `
  // new mapboxgl.Popup().setLngLat(coordinates).setHTML(popupContent).addTo(map)
  // })

  map.on('mouseenter', CHOROPLETH_POINT_LAYER, () => {
    map.getCanvas().style.cursor = 'pointer'
  })

  map.on('mouseleave', CHOROPLETH_POINT_LAYER, () => {
    map.getCanvas().style.cursor = ''
  })
}

const addLableLayer = (mapInstance, pointsData) => {
  if (!pointsData) return
  // Loop through the features and apply the formatting
  const dataClone = JSON.parse(JSON.stringify(pointsData))
  if (dataClone.features)
    dataClone.features = dataClone.features.map((feature) => convertAllFeatureProperties(feature))

  mapInstance.addSource(LABLE_LAYER + '-source', {
    type: 'geojson',
    data: dataClone,
  })
  mapInstance.addLayer({
    id: LABLE_LAYER,
    type: 'symbol',
    source: LABLE_LAYER + '-source',
    layout: {
      'text-field': ['get', 'place_name'],
      'text-offset': [0, -1],
    },
    paint: {
      'text-color': '#000000',
    },
  })
}

const setupMapLayers = (
  mapInstance,
  pointsData,
  setDefualtBounds,
  choroplethData,
  choroplethPointData,
  showLocalityChoropleth
) => {
  addChoroplethLayer(mapInstance, choroplethData)
  addChoroplethPointLayer(mapInstance, choroplethPointData)

  const pointBounds = addPointLayer(mapInstance, pointsData)
  setDefualtBounds(pointBounds)
  addLableLayer(mapInstance, pointsData)

  // Change the cursor to a pointer when over points layer
  mapInstance.on('mouseenter', POINT_LAYER, function () {
    mapInstance.getCanvas().style.cursor = 'pointer'
  })

  // Change it back to the default when not hovering
  mapInstance.on('mouseleave', POINT_LAYER, function () {
    mapInstance.getCanvas().style.cursor = ''
  })

  if (showLocalityChoropleth) {
    mapInstance.setLayoutProperty(CHOROPLETH_LAYER, 'visibility', 'visible')
    mapInstance.setLayoutProperty(BODER_LAYER, 'visibility', 'visible')
    mapInstance.setLayoutProperty(CHOROPLETH_POINT_LAYER, 'visibility', 'visible')
    mapInstance.setLayoutProperty(LABLE_LAYER, 'visibility', 'none')
    mapInstance.setLayoutProperty(POINT_LAYER, 'visibility', 'none')
  } else {
    mapInstance.setLayoutProperty(CHOROPLETH_LAYER, 'visibility', 'none')
    mapInstance.setLayoutProperty(CHOROPLETH_POINT_LAYER, 'visibility', 'none')
    mapInstance.setLayoutProperty(BODER_LAYER, 'visibility', 'none')
    mapInstance.setLayoutProperty(LABLE_LAYER, 'visibility', 'visible')
    mapInstance.setLayoutProperty(POINT_LAYER, 'visibility', 'visible')
  }
}

const addMapLayers = (
  map,
  selectedBasemap,
  pointsData,
  setDefualtBounds,
  choroplethData,
  choroplethPointData,
  showLocalityChoropleth
) => {
  map.setStyle(
    `mapbox://styles/mapbox/${selectedBasemap === 'heatmap' ? 'streets-v11' : selectedBasemap}`
  )

  map.on('style.load', () => {
    if (map.getSource('points')) {
      map.removeLayer(POINT_LAYER)
      map.removeSource('points')
    }

    setupMapLayers(
      map,
      pointsData,
      setDefualtBounds,
      choroplethData,
      choroplethPointData,
      showLocalityChoropleth
    )
  })
}

const HeatMap: React.FC<HeatmapProps> = ({
  filters,
  locationMap,
  setLocationMap,
  map,
  setMap,
  setDefualtBounds,
  data,
  choroplethData,
  choroplethPointData,
  showLocalityChoropleth,
  setShowLocalityChoropleth,
}) => {
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedBasemap, setSelectedBasemap] = useState('heatmap')
  const [pointsData, setPointsData] = useState<MallDetailsResponse['malls'] | null>(null)
  const [pointsVisible, setPointsVisible] = useState(false)

  useEffect(() => {
    try {
      mapboxgl.accessToken = accessToken!

      const mapInstance = new mapboxgl.Map({
        container: mapContainerRef.current as HTMLDivElement,
        style: `mapbox://styles/mapbox/${
          selectedBasemap === 'heatmap' ? 'streets-v11' : selectedBasemap
        }`,
        center: [72.88, 19.08],
        zoom: 10,
      })

      setMap(mapInstance)

      // mapInstance.addControl(new mapboxgl.NavigationControl(), 'bottom-right')

      mapInstance.on('load', () =>
        setupMapLayers(
          mapInstance,
          pointsData,
          setDefualtBounds,
          choroplethData,
          choroplethPointData,
          showLocalityChoropleth
        )
      )

      return () => mapInstance.remove()
    } catch (error) {
      console.error('Error while initialising map: ', error)
    }
  }, [pointsData, selectedBasemap])

  useEffect(() => {
    if (!map && !showLocalityChoropleth) return
    try {
      // Example function to update GeoJSON source data
      function updateSourceData(map, sourceId, newData) {
        const dataClone = JSON.parse(JSON.stringify(newData))
        if (dataClone.features)
          dataClone.features = dataClone.features.map((feature) =>
            convertAllFeatureProperties(feature)
          )

        // Check if the source exists
        if (map?.getSource(sourceId)) {
          // Update the source data with new GeoJSON
          map?.getSource(sourceId).setData(dataClone)

          updateChoroplethLayerPaint(map, CHOROPLETH_LAYER, choroplethColorRange, dataClone)
        } else {
          console.error('Source with id ' + sourceId + ' not found')
        }
      }
      updateSourceData(map, CHOROPLETH_LAYER + '-source', choroplethData)
      updateSourceData(map, CHOROPLETH_POINT_LAYER + '-source', choroplethPointData)
    } catch (error) {
      console.log('Error changing choropleth source data: ', error)
    }
  }, [choroplethData, choroplethPointData])

  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        poi_category: filters.poi_category,
        city: filters.city,
        place_name: filters.place_name,
      }
      try {
        const response: any = data
        // || (await addLoader(async () => await fetchMallDetails(requestData)))
        const templocationMap = {}
        if (response?.features) {
          for (const feature of response.features) {
            const coordinates = feature?.geometry?.coordinates || []
            if (feature?.properties?.place_name) {
              templocationMap[feature.properties.place_name] = coordinates
            }
          }
        }
        setLocationMap((previous) => ({...previous, ...templocationMap}))
        setPointsData(response)
      } catch (error) {
        console.error('Error fetching mall details:', error)
      }
    }

    try {
      fetchData()
    } catch (error) {
      console.error('Error while fetching data: ', error)
    }
  }, [data])

  useEffect(() => {
    if (map && pointsData) {
      addMapLayers(
        map,
        selectedBasemap,
        pointsData,
        setDefualtBounds,
        choroplethData,
        choroplethPointData,
        showLocalityChoropleth
      )
    }
  }, [selectedBasemap, map, pointsData])

  const handleBasemapChange = useCallback(
    (value: string) => {
      setSelectedBasemap(value)
    },
    [setSelectedBasemap]
  )

  const toggleDropdown = useCallback(() => {
    setDropdownOpen(!dropdownOpen)
  }, [setDropdownOpen, dropdownOpen])

  const handleZoomIn = useCallback(() => {
    map?.zoomIn()
  }, [map])

  const handleZoomOut = useCallback(() => {
    map?.zoomOut()
  }, [map])

  const handleLabelToggle = useCallback(() => {
    if (map && !showLocalityChoropleth) {
      if (!pointsVisible) {
        map.setLayoutProperty(LABLE_LAYER, 'visibility', 'visible')
      } else {
        map.setLayoutProperty(LABLE_LAYER, 'visibility', 'none')
      }
      setPointsVisible(!pointsVisible)
    }
  }, [map, pointsVisible, setPointsVisible, showLocalityChoropleth])

  const handleChoroplethToggle = useCallback(() => {
    if (map) {
      if (!showLocalityChoropleth) {
        map.setLayoutProperty(CHOROPLETH_LAYER, 'visibility', 'visible')
        map.setLayoutProperty(BODER_LAYER, 'visibility', 'visible')
        map.setLayoutProperty(CHOROPLETH_POINT_LAYER, 'visibility', 'visible')
        map.setLayoutProperty(LABLE_LAYER, 'visibility', 'none')
        map.setLayoutProperty(POINT_LAYER, 'visibility', 'none')
        setPointsVisible(false)
      } else {
        map.setLayoutProperty(CHOROPLETH_LAYER, 'visibility', 'none')
        map.setLayoutProperty(BODER_LAYER, 'visibility', 'none')
        map.setLayoutProperty(CHOROPLETH_POINT_LAYER, 'visibility', 'none')
        map.setLayoutProperty(POINT_LAYER, 'visibility', 'visible')
      }
    }
    setShowLocalityChoropleth((previous) => !previous)
  }, [map, showLocalityChoropleth, setShowLocalityChoropleth, setPointsVisible])

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        padding: '0.5rem',
        borderRadius: '0.7rem',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      }}
    >
      <div ref={mapContainerRef} className='map-container'>
        <MapControllers
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          toggleDropdown={toggleDropdown}
          dropdownOpen={dropdownOpen}
          handleBasemapChange={handleBasemapChange}
          handleLabelToggle={handleLabelToggle}
          handleChoroplethToggle={handleChoroplethToggle}
        />
      </div>
      <div>
        <MapLegends colorRange={showLocalityChoropleth ? choroplethColorRange : pointColorRange} />
      </div>
    </div>
  )
}

const MapControllers = ({
  handleZoomIn,
  handleZoomOut,
  toggleDropdown,
  dropdownOpen,
  handleBasemapChange,
  handleLabelToggle,
  handleChoroplethToggle,
}) => {
  return (
    <div style={{position: 'absolute', top: '10px', right: '10px', zIndex: 1}}>
      <div>
        <button className='btn btn-icon btn-light w-40px h-40px mt-3' onClick={handleZoomIn}>
          <KTIcon iconName='plus' className='fs-2' />
        </button>
      </div>
      <div>
        <button className='btn btn-icon btn-light w-40px h-40px mt-3' onClick={handleZoomOut}>
          <KTIcon iconName='minus' className='fs-2' />
        </button>
      </div>
      <div className='dropdown'>
        <button className='btn btn-icon btn-light w-40px h-40px mt-3' onClick={toggleDropdown}>
          <KTIcon iconName='abstract-26' className='fs-5' />
        </button>
        <div
          className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}
          style={{left: 'auto', right: '0', width: 'auto'}}
        >
          <button className='dropdown-item' onClick={() => handleBasemapChange('heatmap')}>
            <img src='assets/media/layers/Basemap.png' alt='Basemap' width='30' height='30' />
            <span className='ms-2'>Basemap</span>
          </button>
          <button className='dropdown-item' onClick={() => handleBasemapChange('light-v10')}>
            <img src='assets/media/layers/Light.png' alt='Light' width='30' height='30' />
            <span className='ms-2'>Light</span>
          </button>
          <button className='dropdown-item' onClick={() => handleBasemapChange('dark-v10')}>
            <img src='assets/media/layers/Dark.png' alt='Dark' width='30' height='30' />
            <span className='ms-2'>Dark</span>
          </button>
          <button className='dropdown-item' onClick={() => handleBasemapChange('satellite-v9')}>
            <img src='assets/media/layers/Satellite.png' alt='Satellite' width='30' height='30' />
            <span className='ms-2'>Satellite</span>
          </button>
        </div>
      </div>
      <div>
        <button className='btn btn-icon btn-light w-40px h-40px mt-3' onClick={handleLabelToggle}>
          <span className='fw-bold fs-4 font-sans'>I</span>
        </button>
      </div>
      <div>
        <button
          className='btn btn-icon btn-light w-40px h-40px mt-3'
          onClick={handleChoroplethToggle}
        >
          <span className='fw-bold fs-4 font-sans' title='Locality'>
            L
          </span>
        </button>
      </div>
    </div>
  )
}

const MapLegends = ({colorRange}) => {
  const gradientColors = colorRange.join(', ')

  return (
    <div className='legendContainer'>
      <div className='legendTitle'>Visitor Origin Proportion</div>
      <div
        className='legendGradient'
        style={{background: `linear-gradient(to right, ${gradientColors})`}}
      ></div>
      <div className='legendLabels'>
        <span>Low</span>
        <span>High</span>
      </div>
    </div>
  )
}

export default HeatMap
